import { FilledInput, FormControl, InputLabel } from "@mui/material";
import React from "react";

function CustomTextField({
  name,
  label,
  value,
  type = "text",
  handleChange,
  handleBlur,
  error,
  touched,
}) {

  return (
    <FormControl
      sx={{
        width: "100%",
        marginBottom: "9px",
      }}
      variant="filled"
    >
      <InputLabel
        htmlFor={name}
        sx={{
          fontSize: "16px",
          color: "#C6C6C6 !important",
          fontWeight: touched ? "bold" : "500",
        }}
      >
        {label}
      </InputLabel>
      <FilledInput
        id={name}
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        inputProps={{
          autoComplete: "off", // or 'new-password'
        }}
        sx={{
          borderRadius: "5px !important",
          border: error
            ? "1px solid #ca0700 !important"
            : "1px solid rgba(84, 84, 84, 0.19) !important",
          background: "#f0f0f0 !important",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.05) !important",
          borderBottom: "none",
        }}
      />
    </FormControl>
  );
}

export default CustomTextField;
