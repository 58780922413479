import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { ACADEMY_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};


export const acadmyAPI = createAsyncThunk("acadmyAPI", async () => {
  try {
    const response = await instance.get(ACADEMY_URLS.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const academyStatusUpdateAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.put(ACADEMY_URLS.UPDATE_STATUS(data.slug), data.body);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
)

export const academyDeleteAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.delete(ACADEMY_URLS.DELETE(data.slug));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
)

const acadmy = createSlice({
  name: "acadmy",
  initialState,
  reducers: {
    deleteAcademy: (state, action) => {
      console.log(action.payload.id);
      state.data = state.data.map((academy) => {
        if (academy.slug !== action.payload.slug)
          return academy;

        return {
          ...academy,
          deleted: true
        }
      });
    },
    updateAcademyStatus: (state, action) => {
      state.data = state.data.map((academy) =>
        academy.slug === action.payload.slug
          ? { ...academy, active: action.payload.status }
          : academy
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(acadmyAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(acadmyAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.list;
      console.log(action.payload);
    });
    builder.addCase(acadmyAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { deleteAcademy, updateAcademyStatus } = acadmy.actions;
export default acadmy.reducer;
