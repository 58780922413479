import React from "react";
import { Box, TextField, Typography } from "@mui/material";

const SettingTextFormField = ({
  mainLabel,
  description,
  marginTop = "112px",
  TextFieldRequired = true,
  descriptionColor = "primary.main",
  component,
  value,
  handleChange,
  name,
  placeholder,
  errors,
  touched,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginTop: marginTop,
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {mainLabel}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            lineHeight: "18px",
            color: descriptionColor,
          }}
        >
          {description}
        </Typography>
      </Box>
      <>
        {TextFieldRequired ? (
          <Box>
            <TextField
              sx={{
                width: "500px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  borderWidth: "1px",
                  height: "45px",
                },
              }}
              name={name}
              value={value}
              onChange={handleChange}
              placeholder={placeholder}
            />
            {errors && touched ? (
              <Typography
                sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
              >
                {errors}
              </Typography>
            ) : null}
          </Box>
        ) : (
          <Box>
            {component}
            {errors && touched ? (
              <Typography
                sx={{
                  color: "red",
                  fontSize: "12px",
                  paddingX: "2px",
                  paddingY: "6px",
                }}
              >
                {errors}
              </Typography>
            ) : null}
          </Box>
        )}
      </>
    </Box>
  );
};

export default SettingTextFormField;
