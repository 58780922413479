import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { LANGUAGE_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const languageListAPI = createAsyncThunk("languageListAPI", async () => {
  try {
    const response = await instance.get(LANGUAGE_URL.LIST);
    return response.data;
  } catch (error) {
    console.error("Error: ", error);
    return error;
  }
});


export const LanguageGetByIdAPI = createAsyncThunk(
  "languageGetById",
  async (data) => {
    try {
      const response = await instance.get(LANGUAGE_URL.GET(data.id));
      return response;
    } catch (error) {
      console.error("Error: ", error);
      return error;
    }
  }
);

export const createLanguageAPI = createAsyncThunk(
  "createLanguage",
  async (data) => {
    try {
      const response = await instance.post(LANGUAGE_URL.CREATE, data);
      return response;
    } catch (error) {
      console.error("Error: ", error);
      return error;
    }
  }
);

export const LanguageEditAPI = createAsyncThunk(
  "languageEdit",
  async (data) => {
    try {
      const response = await instance.put(
        LANGUAGE_URL.UPDATE(data.id),
        data.formdata
      );
      return response;
    } catch (error) {
      console.error("Error: ", error);
      return error;
    }
  }
);

export const languageStatusUpdateAPI = createAsyncThunk(
  "languageStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(
        LANGUAGE_URL.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (error) {
      console.error("Error: ", error);
      return error;
    }
  }
);

export const languageDeleteAPI = createAsyncThunk(
  "languageDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(LANGUAGE_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.error("Error: ", error);
      return error;
    }
  }
);

const languageSlice = createSlice({
  name: "languageList",
  initialState,
  reducers: {
    languageStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },

    languageDelete: (state, action) => {
      let data = state.data?.filter((item) => item._id !== action.payload.id);
      state.data = data;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(languageListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(languageListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list
    });
    builder.addCase(languageListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { languageStatusUpdate, languageDelete } = languageSlice.actions;
export default languageSlice.reducer;
