import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../Components/CustomPagination";
import DataInsertIntoTable from "../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  updateUserStatus,
  userDeleteAPI,
  userStatusUpdateAPI,
  usersListAPI,
} from "../../Redux/slice/users";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { downloadCSV } from "../../utils/utils";

const UsersList = () => {
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [keyOfSort, setKeyOfSort] = useState("totalNoBookings");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const userData = useSelector((state) => state.users.data);

  const head = tableHead;
  // const body = tableBody;
  const sortedBody = users?.sort((a, b) => {
    const nameA = a[keyOfSort]?.content?.toUpperCase();
    const nameB = b[keyOfSort]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleExport = () => {
    const getAddreStr = (address) =>
      `${address?.address} ${address?.city}; ${address?.state}`;

    const csv = userData.map((row) => {
      return {
        id: row?._id,
        slug: row?.slug,
        name: row?.fullName,
        phoneNumber: row?.coachProfile?.phoneNumber,
        email: row?.email,
        totalNoBookings: "NA",
        profileVisits: "NA",
        address: getAddreStr(row?.address),
        status: row?.status,
      };
    });
    downloadCSV(csv, "users");
  };

  const handleDelete = async () => {
    for (let id of selectedItems) {
      dispatch(deleteUser({ id: id }));
      dispatch(userDeleteAPI({ id: id }));
    }
  };

  useEffect(() => {
    const handleStatus = (id) => (event) => {
      dispatch(updateUserStatus({ id, status: event?.target?.checked }));
      dispatch(
        userStatusUpdateAPI({ id, body: { status: event?.target?.checked } })
      );
    };
    if (!userData) return;
    console.log(userData);
    const arr = userData?.map((user) => {
      return {
        actions: [
          {
            content: (
              <Switch
                onChange={handleStatus(user?._id)}
                checked={user?.active}
              />
            ),
          },
          {
            content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
          },
          { type: "ButtonWithImg", content: editIcon },
        ],
        user: [
          {
            type: "verifiedDetails",
            content: {
              img: editIcon,
              name: user?.fullName,
              phoneNumber: user?.mobile,
              verified: user?.mobileVerified,
            },
          },
        ],
        id: [{ type: "text", content: user?._id }],
        // totalNoBookings: [{ type: "text", content: "02" }],
        emailID: [{ type: "email", content: user?.email || "NA" }],
        address: [{ type: "text", content: user?.city || "NA" }],
        status: [
          {
            type: "status",
            content: user?.deleted ? "deleted" : user?.active ? "active" : "inActive",
          },
        ],
      };
    });
    setUsers(arr);
    setLoading(false);
  }, [dispatch, userData]);

  useEffect(() => {
    dispatch(usersListAPI());
  }, [dispatch]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Users List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText} onClick={handleExport}>
                Export
              </Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {users.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{users.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>
          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default UsersList;
