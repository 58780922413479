export const initialEditProfileFormikValues = {
    name: "",
    fullName: "",
    mobile: "",
    email: "",
    description: "",
    sports: [
        {
            id: null,
            label: "",
        },
    ],
    addresses: [
        {
            label: "",
            pincode: "",
            city: "",
            coachingPeriod: [
                {
                    type: "Days",
                    typeDetails: ["All Days"],
                },
                {
                    type: "Weeks",
                    typeDetails: ["All Weeks"],
                },
                {
                    type: "Months",
                    typeDetails: ["All Months"],
                },
            ],
            coachingType: "",
            slots: [],
            ageGroup: [],
            genderTeachBy: [],
        },
    ],
    groupCoachingPriceRange: {
        min: null,
        max: null,
    },
    privateCoachingPriceRange: {
        min: null,
        max: null,
    },
    coaches: [
        {
            id: null,
            label: "",
        },
    ],
};