import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styles } from "./styles";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import superAcademyIcon from "../../../Assets/Images/superAcademyIcon.svg";
import SuperLoginTable from "./superLoginTable";
import IconForSuperAdmin from "../../../Assets/Images/superAdminRowIcon.svg";
import IconForAdmin from "../../../Assets/Images/adminRowIcon.svg";
import IconForEdite from "../../../Assets/Images/pencilIcon.svg";
const Admin = () => {
  const rows = [
    {
      admin: (
        <Box style={{ display: "flex", gap: "4%", marginTop: "5%" }}>
          <img src={IconForSuperAdmin} alt="IconSuperAdmin" />
          <Typography sx={styles.superAdmin}>Super Admin</Typography>
          <Typography sx={styles.superAdminName}>Ravi Sharma</Typography>
          <img
            style={{ marginLeft: "4%" }}
            src={IconForEdite}
            alt="IconForEdite"
          />
        </Box>
      ),
    },
    {
      admin: (
        <Box style={{ display: "flex", gap: "4%" }}>
          <img src={IconForAdmin} alt="IconForAdmin" />
          <Typography sx={styles.admin}>Admin 1</Typography>
          <Typography sx={styles.adminName}>Rohith</Typography>
          <img
            style={{ marginLeft: "12.5%" }}
            src={IconForEdite}
            alt="IconForEdite"
          />
        </Box>
      ),
    },
    {
      admin: (
        <Box style={{ display: "flex", gap: "4%" }}>
          <img src={IconForAdmin} alt="IconForAdmin" />
          <Typography sx={styles.admin}>Admin 2</Typography>
          <Typography sx={styles.adminName}>Kiran Kumar</Typography>
          <img
            style={{ marginLeft: "3%" }}
            src={IconForEdite}
            alt="IconForEdite"
          />
        </Box>
      ),
    },
  ];

  const headingOfTable = ["ADMIN", "E-MAIL", "PH. NO"];

  return (
    <Box sx={styles.adminProfileMainBox}>
      <Box
        sx={{
          padding: "15.75px",
          height: "15.75px",
          top: "214.17px",
          left: "1429.62px",
          color: "#242424",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CloseRoundedIcon />
      </Box>
      <Box sx={styles.headerContainer}>
        <img
          style={{ marginLeft: "3%" }}
          src={superAcademyIcon}
          alt="superAcademyIcon"
        />
        <Link
          to="/academy/academyprofile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Box sx={styles.textContainer}>
            <Typography sx={styles.academyName}>
              Sparks Cricket Academy
            </Typography>
            <Typography sx={styles.location}>Vadodara, Gujarat</Typography>
          </Box>
        </Link>
      </Box>
      <SuperLoginTable headingOfTable={headingOfTable} bodyOfTable={rows} />
    </Box>
  );
};

export default Admin;
