import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styles } from "./styles";

const SuperLoginTable = ({ headingOfTable, bodyOfTable }) => {
  return (
    <TableContainer sx={styles.tableContainer}>
      <Table>
        <TableHead sx={styles.tableHead}>
          <TableRow>
            {headingOfTable.map((item, index) => (
              <TableCell key={index} sx={styles.tableHeadCell}>
                {item}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {bodyOfTable.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {headingOfTable.map((item, columnIndex) => (
                <TableCell key={columnIndex} sx={styles.tableBodyCell}>
                  {row[item.toLowerCase()]}
                </TableCell>
              ))}
              <TableCell sx={styles.tableBodyCloseIconCell}>
                {row.icon ? row.icon : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SuperLoginTable;
