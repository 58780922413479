import React, { useEffect } from "react";
import CustomFormikHook from "../../../Hooks/CustomFormikHook";
import { fillInitialValues } from "./fillInitialValues";
// import { useDispatch } from "react-redux";
import { initialEditProfileFormikValues } from "./initialFormikProfileEditValues";
import { editCoachProfileSchema } from "../../../Schemas";
import { Box, TextField, useMediaQuery } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import InputFieldsWithSearch from "../../../Components/EditProfileComponents/inputFieldsWithSearch";
import AddButtonWithLabel from "../../../Components/EditProfileComponents/addButtonWithLabel";
import AddressFields from "../../../Components/EditProfileComponents/addressFields";
import CoachingPeriodDropdown from "../../../Components/EditProfileComponents/coachingPeriodDropdown";
import CoachingTypeAndTimeSlot from "../../../Components/EditProfileComponents/coachingTypeAndTimeSlot";
import SelectAgeGroup from "../../../Components/EditProfileComponents/selectAgeGroup";
import SelectGenderUTeach from "../../../Components/EditProfileComponents/selectGenderUTeach";
import CoachingPrices from "../../../Components/EditProfileComponents/coachingPrices";
import DescribeYourSelf from "../../../Components/EditProfileComponents/describeYourSelf";
import YourExperience from "../../../Components/EditProfileComponents/yourExperience";
import CustomButton from "../../../Components/EditProfileComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  getCoachProfile,
  updateCoachprofile,
} from "../../../Redux/slice/editProfileSlice";
import { toast } from "sonner";
import { BASE_URL, SPORTSLIST_URL } from "../../../constants";

function EditCoachProfile() {
  const { coach } = useSelector((state) => state.editProfile);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1000px)");

  const handleSubmit = async (values) => {
    const coachData = { ...values };
    coachData.sports = values.sports?.map((sport) => sport.id);
    coachData.academy = values.academy
      ?.map((academy) => academy.id)
      .filter((id) => id);
    coachData.address = values.addresses?.map((address) => {
      return {
        ...address,
        slots: address.slots?.map((slot) => {
          return {
            ...slot,
            timing: { to: slot.timing.from, until: slot.timing.to },
          };
        }),
      };
    });

    let res = await dispatch(
      updateCoachprofile({ body: coachData, id: searchParams.get("id") })
    ).unwrap();

    if (res?.data?.message) {
      toast.success("Profile Updated Successfully");
      navigate(-1);
    } else {
      toast.error("Something went wrong");
    }
  };

  const formik = CustomFormikHook(
    initialEditProfileFormikValues,
    editCoachProfileSchema,
    handleSubmit
  );

  useEffect(() => {
    formik.validateForm();
  }, []);

  useEffect(() => {
    dispatch(getCoachProfile({ id: searchParams.get("id") }));
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (coach) fillInitialValues(coach, formik);
  }, [coach]);

  const webStyle = {
    position: "relative",
    top: "160px",
    margin: "auto",
    width: "65%",
    padding: 0,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 0,
    outline: "none",
    border: "none",
  };

  const textFieldStyles = {
    width: "100%",
    margin: "10px 0",
    // ...textFields.dottedBottomBorder,
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleAddAddress = () => {
    const addresses = [
      ...formik.values.addresses,
      initialEditProfileFormikValues.addresses[0],
    ];
    formik.setFieldValue("addresses", addresses);
  };

  const handleRemoveAddress = (index) => () => {
    const addresses = formik.values.addresses.filter((_, i) => i !== index);
    formik.setFieldValue("addresses", addresses);
  };

  const searchAcademies = async (query) => {
    let res = await fetch(`${BASE_URL}${SPORTSLIST_URL}?q=${query}`, {
      method: "GET",
    }).then((res) => res.json());

    let list = res?.data?.map((academy) => ({
      id: academy.id,
      label: academy.name,
      image: "",
    }));

    return { data: { list: list } };
  };

  const searchSports = async (query) => {
    let res = await fetch(`${BASE_URL}${SPORTSLIST_URL}?q=${query}`, {
      method: "GET",
    }).then((res) => res.json());

    let list = res?.data?.map((sport) => ({
      _id: sport._id,
      label: sport.name,
      image: "",
    }));
    return { data: { list: list } };
  };

  return (
    <section>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          overflowY: "hidden",
          backgroundColor: "#fff",
        }}
      >
        <Box sx={{ ...(!isMobile && webStyle) }}>
          <CloseIcon
            onClick={handleClose}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "38px",
              right: "42px",
            }}
          />
          <Box
            sx={{
              padding: "60px 50px",
              ...(!isMobile && {
                padding: "60px 30px",
                textAlign: "center",
                borderBottom: "1px solid #D3D1D1",
              }),
            }}
          >
            <span
              style={{
                fontSize: "32px",
                fontWeight: 600,
              }}
            >
              Edit Profile
            </span>
          </Box>

          <Box
            sx={{
              width: isMobile ? "80%" : "65%",
              margin: "20px auto",
              marginBottom: "50px",
            }}
          >
            <Box sx={{ width: { xs: "100%", sm: "90%", md: "80%" } }}>
              <TextField
                variant="standard"
                type="text"
                id="fullName"
                name="fullName"
                label="*Full Name"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                error={
                  formik.touched.fullName && Boolean(formik.errors.fullName)
                }
                helperText={formik.touched.fullName && formik.errors.fullName}
                InputProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "400",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: "500",
                  },
                }}
                sx={textFieldStyles}
              />
              <InputFieldsWithSearch
                formik={formik}
                formikFieldName={"sports"}
                label={"Enter Sport"}
                title={"Enter the sport that you teach"}
                search={searchSports}
                buttonLabel={"Add Another Sport"}
              />
              {formik.values.addresses?.map((_, index) => (
                <React.Fragment key={index}>
                  <AddressFields
                    key={index}
                    formik={formik}
                    addressIndex={index}
                    title={
                      index === 0
                        ? "Primary Coaching Address"
                        : "Another Coaching Address"
                    }
                  />

                  {index === 0 && (
                    <AddButtonWithLabel
                      label={"Add another coaching address"}
                      onClick={handleAddAddress}
                    />
                  )}
                  {index !== 0 && (
                    <AddButtonWithLabel
                      label={"remove coaching address"}
                      onClick={handleRemoveAddress(index)}
                    />
                  )}

                  <CoachingPeriodDropdown
                    formik={formik}
                    addressIndex={index}
                  />
                  <CoachingTypeAndTimeSlot
                    formik={formik}
                    addressIndex={index}
                  />
                  <SelectAgeGroup formik={formik} addressIndex={index} />
                  <SelectGenderUTeach formik={formik} addressIndex={index} />
                </React.Fragment>
              ))}

              <DescribeYourSelf formik={formik} />
              <YourExperience formik={formik} />
              <CoachingPrices
                formik={formik}
                addresses={formik.values.addresses}
              />
              <InputFieldsWithSearch
                formik={formik}
                formikFieldName={"academies"}
                label={"Enter Academy Name"}
                title={"Enter your coaching academy (optional)"}
                search={searchAcademies}
                buttonLabel={"Add Another Academy"}
              />
              <CustomButton
                width="100%"
                // type="submit"
                clickEvent={formik.handleSubmit}
                disabled={!formik.isValid}
              >
                Submit
              </CustomButton>
              <Box
                sx={{ width: "100%", height: "100px", marginTop: "250px" }}
              ></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  );
}

export default EditCoachProfile;
