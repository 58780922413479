import { initialEditProfileFormikValues } from "./initialFormikProfileEditValues";

export const fillInitialValues = (coach, formik) => {
    console.log(coach);

    let addresses = coach?.address?.map(avail => ({
        ...avail,
        label: avail?.label || "",
        pincode: avail?.pincode || "", 
        city: avail?.city || "",
        coachingPeriod: avail?.coachingPeriod?.map(period => ({
            type: period?.type,
            typeDetails: period?.typeDetails
        })) || [],
        coachingType: avail?.coachingType?._id || "",
        ageGroup: avail.ageGroup?.map((age) => age._id) || [],
        genderTeachBy: avail.genderTeachBy?.map(gender => gender?._id) || [],
        slots: avail.slots?.map((slot) => ({
            coachingType: slot.coachingType,
            timing: {
                from: slot.timing?.to,
                to: slot.timing?.until,
            },
            label: slot.label._id,
        })) || [],
    }))

    let sports = coach?.sports?.map(sport => ({ id: sport?._id, label: sport?.label?.toLowerCase() }))

    formik.setValues({
        ...initialEditProfileFormikValues,
        fullName: coach?.User?.fullName || initialEditProfileFormikValues.fullName,
        bio: coach?.bio || initialEditProfileFormikValues.bio,
        experince: coach?.experince || initialEditProfileFormikValues.experince,
        groupCoachingPriceRange: coach?.groupCoachingPriceRange || initialEditProfileFormikValues.groupCoachingPriceRange,
        privateCoachingPriceRange: coach?.privateCoachingPriceRange || initialEditProfileFormikValues.privateCoachingPriceRange,
        addresses: addresses?.length ? addresses : initialEditProfileFormikValues.addresses,
        sports: sports?.length ? sports : initialEditProfileFormikValues.sports,
    });

}