import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import InputError from "./inputError";

function SelectCoachingType({ formik, coachingTypeList, addressIndex }) {
  const handleCoachingType = (e) => {
    if (e.target.value === formik.values.coachingType) return;
    formik.setFieldValue(`addresses[${addressIndex}].slots`, []);
    formik.setFieldValue(
      `addresses[${addressIndex}].coachingType`,
      e.target.value
    );
    formik.setFieldValue(`addresses[${addressIndex}].timeSlots`, []);
  };

  const isChecked = (id) => {
    return (
      String(formik.values.addresses[addressIndex].coachingType) === String(id)
    );
  };

  return (
    <Box sx={{ marginTop: "45px", marginBottom: "24px" }}>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "24px",
        }}
      >
        Select Coaching Type
      </Typography>
      <RadioGroup aria-label="coaching-type" name="selectingCoachingType">
        {coachingTypeList?.map((ctype, i) => (
          <FormControlLabel
            key={i}
            name={ctype._id}
            value={ctype._id}
            checked={isChecked(ctype._id)}
            onChange={handleCoachingType}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#242424",
                  },
                }}
              />
            }
            label={ctype.label?.toLowerCase()}
            sx={{
              color: "#242424",
              fontWeight: 500,
              fontSize: "16px",
              "& .MuiFormControlLabel-label": {
                textTransform: "capitalize",
              },
            }}
          />
        ))}
      </RadioGroup>

      {formik.touched.addresses?.length > 0 &&
        formik.errors.addresses?.length > 0 &&
        formik.touched.addresses[addressIndex]?.coachingType &&
        formik.errors.addresses[addressIndex]?.coachingType && (
          <InputError
            error={formik.errors.addresses[addressIndex].coachingType}
          />
        )}
    </Box>
  );
}

export default SelectCoachingType;
