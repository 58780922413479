export const styles = {
  headingContainer: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTitle: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  filterButtonContainer: {
    alignItems: "center",
    display: "flex",
  },
  filterButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  filterButtonText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  exportButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportButtonText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  addButton: {
    justifyContent: "center",
    backgroundColor: "primary.dark",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  addButtonIcon: {
    color: "primary.light",
    padding: "0px",
  },
  categoryFormBox: {
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
    paddingY: "20px",
    paddingX: "40px",
    boxShadow: "8px 8px 9px 0px #0000001A",
  },
  editGenderFormStatusText: {
    fontSize: "14px",
    fontWeight: "500",
    marginY: "6px",
  },
  editGenderFormButtonMainBox: {
    marginTop: "30px",
    display: "flex",
  },
  editGenderFormUpdateButtonIcon: { marginRight: "5px", fontSize: "18px" },
  editGenderFormUpdateButton: {
    color: "primary.light",
    marginRight: "40px",
    textTransform: "capitalize",
    alignItems: "center",
  },
  editGenderFormResetButtonIcon: {
    marginRight: "5px",
    fontSize: "18px",
    transform: "rotate(270deg)",
  },
  editGenderFormResetButton: {
    color: "primary.light",
    backgroundColor: "#FF5B5B",
    textTransform: "capitalize",
  },
};
