import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { TIME_SLOT_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const timeSlotListAPI = createAsyncThunk("timeSlotListAPI", async () => {
  try {
    const response = await instance.get(TIME_SLOT_URL.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const TimeSlotGetByIdAPI = createAsyncThunk(
  "TimeSlotGetById",
  async (data) => {
    try {
      const response = await instance.get(TIME_SLOT_URL.GET(data.id));
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const timeSlotCreateAPI = createAsyncThunk(
  "timeSlotCreate",
  async (formdata) => {
    try {
      const response = await instance.post(TIME_SLOT_URL.CREATE, formdata);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const TimeSlotEditAPI = createAsyncThunk(
  "timeSlotEdit",
  async (data) => {
    try {
      const response = await instance.put(
        TIME_SLOT_URL.UPDATE(data.id),
        data.formdata
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const timeSlotStatusUpdateAPI = createAsyncThunk(
  "timeSlotStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(
        TIME_SLOT_URL.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const timeSlotDeleteAPI = createAsyncThunk(
  "timeSlotDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(TIME_SLOT_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const timeSlotList = createSlice({
  name: "timeSlotList",
  initialState,
  reducers: {
    timeSlotStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
    deleteTimeSlot: (state, action) => {
      let data = state.data?.filter((item) => item._id !== action.payload.id);
      state.data = data;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(timeSlotListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(timeSlotListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list;
    });
    builder.addCase(timeSlotListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { timeSlotStatusUpdate, deleteTimeSlot } = timeSlotList.actions;
export default timeSlotList.reducer;
