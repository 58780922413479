import React from "react";
import { Box, Typography } from "@mui/material";

const CustomUploadButtonComponent = ({ label, children, onChange, value }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "15px",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {label}
        </Typography>
      </Box>
      <label>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: "10px",
            padding: "5px 10px",
            border: "1px solid #E0E0E0",
            backgroundColor: "primary.main",
            color: "white",
          }}
        >
          {children}
          <input
            onChange={onChange}
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
          />
        </Box>
      </label>
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {value?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomUploadButtonComponent;
