import { Box, Typography } from "@mui/material";
import React from "react";
import CustomTextField from "./customTextField";
import InputError from "./inputError";

function AddressFields({ formik, addressIndex, title }) {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        {title}
      </Typography>
      <Box>
        <CustomTextField
          name={`addresses.${addressIndex}.label`}
          label={"Enter Address"}
          value={formik.values.addresses[addressIndex]?.label}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.label
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.label &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.label
          }
        />
        <CustomTextField
          name={`addresses.${addressIndex}.pincode`}
          label={"pincode"}
          type="number"
          value={formik.values.addresses[addressIndex]?.pincode}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.pincode
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.pincode &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.pincode
          }
        />
        <CustomTextField
          name={`addresses.${addressIndex}.city`}
          label={"City"}
          value={formik.values.addresses[addressIndex]?.city}
          handleChange={formik.handleChange}
          handleBlur={formik.handleBlur}
          touched={
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.city
          }
          error={
            formik.errors.addresses &&
            formik.errors.addresses[addressIndex]?.city &&
            formik.touched.addresses &&
            formik.touched.addresses[addressIndex]?.city
          }
        />
        {formik.touched.addresses && formik.errors.addresses && (
          <>
            <InputError
              error={formik.errors.addresses[addressIndex]?.address}
            />
            <InputError
              error={formik.errors.addresses[addressIndex]?.pinCode}
            />
            <InputError
              error={formik.errors.addresses[addressIndex]?.city}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default AddressFields;
