import { SERVICES } from "./API/urlConstants";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const AUTH_URL = {
  LOGIN: `${BASE_URL}/crms/auth/login`,
}

export const LANGUAGE_URL = {
  LIST: `/crms/master/languages`,
  CREATE: `/crms/master/languages`,
  GET: (id) => `/crms/master/languages/${id}`,
  UPDATE: (id) => `/crms/master/languages/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/languages/${id}/status`,
  DELETE: (id) => `/crms/master/languages/${id}`,
};

export const AGE_GROUP_URL = {
  LIST: `/crms/master/ageGroups`,
  CREATE: `/crms/master/ageGroups`,
  GET: (id) => `/crms/master/ageGroups/${id}`,
  UPDATE: (id) => `/crms/master/ageGroups/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/ageGroups/${id}/status`,
  DELETE: (id) => `/crms/master/ageGroups/${id}`,
};

export const COACHING_TYPE_URL = {
  LIST: `/crms/master/coachingTypes`,
  CREATE: `/crms/master/coachingTypes`,
  GET: (id) => `/crms/master/coachingTypes/${id}`,
  UPDATE: (id) => `/crms/master/coachingTypes/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/coachingTypes/${id}/status`,
  DELETE: (id) => `/crms/master/coachingTypes/${id}`,
};

export const GENDER_URL = {
  LIST: `/crms/master/genders`,
  CREATE: `/crms/master/genders`,
  GET: (id) => `/crms/master/genders/${id}`,
  UPDATE: (id) => `/crms/master/genders/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/genders/${id}/status`,
  DELETE: (id) => `/crms/master/genders/${id}`,
};

export const TIME_SLOT_URL = {
  LIST: `/crms/master/timePeriods`,
  CREATE: `/crms/master/timePeriods`,
  GET: (id) => `/crms/master/timePeriods/${id}`,
  UPDATE: (id) => `/crms/master/timePeriods/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/timePeriods/${id}/status`,
  DELETE: (id) => `/crms/master/timePeriods/${id}`,
};

export const SPORT_URL = {
  LIST: `/crms/master/sports`,
  CREATE: `/crms/master/sports`,
  GET: (id) => `/crms/master/sports/${id}`,
  UPDATE: (id) => `/crms/master/sports/${id}`,
  UPDATE_STATUS: (id) => `/crms/master/sports/${id}/status`,
  DELETE: (id) => `/crms/master/sports/${id}`,
  CATEGORIES: `/crms/master/sports/sport-category`,
};

export const COACH_URL = {
  LIST: `/crms/coaches/all`,
  GET: (id) => `/crms/coaches/coach/${id}`,
  UPDATE: (id) => `/crms/coaches/coach/${id}`,
  UPDATE_STATUS: (id) => `/crms/coaches/coach/${id}/status`,
  DELETE: (id) => `/crms/coaches/coach/${id}`,
}

export const ACADEMY_URLS = {
  LIST: `crms/academies/all?page=1&limit=10`,
  GET: (slug) => `crms/academies/academy/${slug}`,
  UPDATE: (slug) => `crms/academies/academy/${slug}`,
  UPDATE_STATUS: (slug) => `crms/academies/academy/${slug}/status`,
  DELETE: (slug) => `crms/academies/academies/academy/${slug}`,
};

export const USER_URLS = {
  LIST: `crms/users/all`,
  GET: (id) => `crms/users/user/${id}`,
  UPDATE: (id) => `crms/users/user/${id}`,
  UPDATE_STATUS: (id) => `crms/users/user/${id}/status`,
  DELETE: (id) => `crms/users/user/${id}`,
}

export const DETAILS_URL = "crms/createStoreDetail";
export const STORELIST_URL = "crms/store-list";
export const TARGETING_URL = "crms/createStoreTargeting";
export const PAYOUT_URL = "crms/createStorePayout";
export const TRACKING_URL = "crms/createStoreOnlineTracking";
export const OFFER_URL = "crms/create-offer";
export const OFFERLIST_URL = "crms/offer-list";
export const CATEGORY_URL = "crms/create-category";
export const CATEGORYLIST_URL = "crms/category-list";
export const GENDERLIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/gender`;
export const USERLIST_URL = "crms/users";
export const SETTING_URL = "crms/settings";


export const createLanguage_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/language/create`;
export const LANGUAGELIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/language`;
export const AGEGROUPCREATE_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/create`;
export const AGEGROUPLIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group`;
export const SPORTSCREATE_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/create`;
export const SPORTSLIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/sport`;
export const SPORTSCATEGORYCREATE_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/create`;
export const SPORTSCATEGORYLIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category`;
export const COACHINGTYPECREATE_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/create`;
export const COACHINGTYPELIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type`;
export const TIMESLOTCREATE_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/create`;
export const TIMESLOTLIST_URL = `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period`;
export const COACHLIST_URL = `${SERVICES.COACH_SERVICE}/caoches/all`;
export const COACH_DELETE = (id) => `${SERVICES.COACH_SERVICE}/caoches/delete?id=${id}`;
export const COACH_STATUS = (id) => `${SERVICES.COACH_SERVICE}/caoches/settings/live?id=${id}`;

export const LANGUAGEEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/language/${id}`;
export const AGEGROUPEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/${id}`;
export const COACHINGTYPEEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/${id}`;
export const GENDEREDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/gender/${id}`;
export const TIMESLOTEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/${id}`;
export const SPORTSCATEGORYEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/${id}`;
export const SPORTSEDIT_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/${id}`;

export const AGEGROUPSTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/age-group/${id}/status`;
export const COACHINGTYPESTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/coaching-type/${id}/status`;
export const LANGUAGESTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/language/${id}/status`;
export const GENDERSTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/gender/${id}/status`;
export const TIMESLOTSTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/time-period/${id}/status`;
export const SPORTSTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/sport/${id}/status`;
export const SPORTSCATEGORYSTATUSUPDATE_URL = (id) =>
  `${SERVICES.WEBSITE_MASTER_SERVICE}/sport-category/${id}/status`;
export const GET_COACH_PROFILE = (id) =>
  `${SERVICES.COACH_SERVICE}/profile?id=${id}`;
export const UPDATE_COACH_PROFILE = (id) =>
  `${SERVICES.COACH_SERVICE}/profile/update?id=${id}`;
export const COCHING_PERIODS_ENUMS = `${SERVICES.COACH_SERVICE}/signup/coachingPeriods`;
