export const styles = {
  mainBox: {
    paddingX: "25px",
    paddingY: "20px",
    backgroundColor: "#FFFFFF",
    flexgrow: "1",
    height: "100%",
  },
  pageHeading: {
    color: "#364A63",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "40px",
  },
  pageHeadingBaseText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "23px",
  },
  tableContainer: {
    borderRadius: "10px",
    border: "1px",
    marginLeft: "10px",
    marginRight: "70px",
    boxShadow: "0px 4px 30px 0px #00000026",
    maxWidth: "90%",
  },
  tableHead: {
    backgroundColor: "#F2F2F2",
  },
  tableHeadCell: {
    color: "primary.main",
    fontWeight: "600",
    fontSize: "14px",
    letterSpacing: "2.2px",
  },
  tableBodyCell: {
    color: "primary.dark",
    fontWeight: "400",
    fonSize: "16px",
    borderRight: "1px solid #E5E9F2",
    borderBottom: "none",
  },
  tableBodyCloseIconCell: { borderBottom: "none" },
};
