import { initialEditProfileFormikValues } from "./initialFormikProfileEditValues";

export const fillInitialValues = (academy, formik) => {
    let addresses = [{
        ...initialEditProfileFormikValues.addresses[0],
        label: academy?.address?.label || initialEditProfileFormikValues.addresses[0].label,
        pincode: academy?.address?.pincode || initialEditProfileFormikValues.addresses[0].pincode,
        city: academy?.address?.city || initialEditProfileFormikValues.addresses[0].city,
        coachingPeriod: academy?.address?.coachingPeriod || initialEditProfileFormikValues.addresses[0].coachingPeriod,
        coachingType: academy?.address?.coachingType?._id || initialEditProfileFormikValues.addresses[0].coachingType,
        ageGroup: academy?.address?.ageGroup?.map(a => a._id) || initialEditProfileFormikValues.addresses[0].ageGroup,
        genderTeachBy: academy?.address?.genderTeachBy?.map(g => g._id) || initialEditProfileFormikValues,
        slots: academy?.address?.slots?.map(slot => ({
            coachingType: slot?.coachingType || "",
            label: slot?.label?._id || "",
            timing: {
                from: slot?.timing?.to || "",
                to: slot?.timing?.until || "",
            },
        }))
    }]
    
    let sports = academy?.sports?.map(sport => ({ id: sport?._id, label: sport?.label?.toLowerCase() }))

    formik.setValues({
        ...initialEditProfileFormikValues,
        name: academy?.name || initialEditProfileFormikValues.name,
        fullName: academy?.fullName || initialEditProfileFormikValues.fullName,
        mobile: academy?.mobile || initialEditProfileFormikValues.mobile,
        email: academy?.email || initialEditProfileFormikValues.email,
        description: academy?.description || initialEditProfileFormikValues.description,
        groupCoachingPriceRange: academy?.groupCoachingPriceRange || initialEditProfileFormikValues.groupCoachingPriceRange,
        privateCoachingPriceRange: academy?.privateCoachingPriceRange || initialEditProfileFormikValues.privateCoachingPriceRange,
        addresses: addresses?.length ? addresses : initialEditProfileFormikValues.addresses,
        sports: sports?.length ? sports : initialEditProfileFormikValues.sports,
        coaches: initialEditProfileFormikValues.coaches
    });
}