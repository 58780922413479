import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TimePeriodSelector from "./timePeriodSelector";
import CloseIcon from "@mui/icons-material/Close";

function CoachingPeriodDropdown({ formik, addressIndex }) {
  const periodTyps = ["Days", "Weeks", "Months"];
  const [periodType, setPeriodType] = useState("Days");
  const [periodTypeIndex, setPeriodTypeIndex] = useState(0);

  const getIndex = (arr, find) => {
    const index = arr?.findIndex((tPeriod) => tPeriod.type === find);
    return index;
  };

  const handleDelete = (period) => () => {
    const index = getIndex(
      formik.values.addresses[addressIndex].coachingPeriod,
      periodType
    );
    if (index >= 0) {
      const values = [...formik.values.addresses[addressIndex].coachingPeriod];
      const typeDetails = formik.values.addresses[addressIndex]?.coachingPeriod[
        index
      ]?.typeDetails?.filter((tPeriod) => tPeriod !== period);

      values[index] = { ...values[index], typeDetails };

      if (values[index].typeDetails.length === 0) {
        values.splice(index, 1);
      }
      formik.setFieldValue(`addresses[${addressIndex}].coachingPeriod`, [
        ...values,
      ]);
    } else {
    }
  };

  const isPeriodTypeDone = (pType) => {
    return formik.values.addresses[addressIndex].coachingPeriod?.find(
      (period) => period.type === pType
    );
  };

  const handleChangePeriodType = (periodt) => () => {
    setPeriodType(periodt);
  };

  useEffect(() => {
    setPeriodTypeIndex(
      getIndex(formik.values.addresses[addressIndex].coachingPeriod, periodType)
    );
  }, [formik.values.addresses, addressIndex, periodType]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "15px",
        transition: "0.5s",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography>
          Coaching Period
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            border: "1px solid rgba(84, 84, 84, 0.19)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            padding: "10px",
            color: "#c6c6c6",
            backgroundColor: "#fff",
          }}
        >
          {periodTyps.map((periodt, i) => (
            <React.Fragment key={i}>
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Typography
                  key={i}
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    cursor: "pointer",
                    ...(periodType === periodt && { color: "#242424" }),
                  }}
                  onClick={handleChangePeriodType(periodt)}
                >
                  {periodt}
                </Typography>
                <Box
                  sx={{
                    width: "15px",
                    height: "2px",
                    borderRadius: "2px",
                    backgroundColor: isPeriodTypeDone(periodt)
                      ? "#242424"
                      : "#c6c6c6",
                  }}
                />
              </Box>
              {i !== periodTyps.length - 1 && "|"}
            </React.Fragment>
          ))}
        </Box>
      </Stack>
      <Box
        sx={{
          transition: "0.5s",
          "&:hover #timePeriodSelector": {
            display: "flex",
          },
          "&:hover .arrowIcon": {
            transform: "rotate(180deg)",
            transition: "0.2s",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            border: "1px solid rgba(84, 84, 84, 0.19)",
            boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
            padding: "15px 20px",
            borderRadius: "5px",
            color: "#c6c6c6",
            fontSize: "16px",
            fontWeight: 500,
            cursor: "pointer",
            ...((formik.touched?.addresses?.length > 0 &&
              formik.touched?.addresses[addressIndex]?.coachingPeriod &&
              formik.errors?.addresses?.length > 0 &&
              formik.errors?.addresses[addressIndex]?.coachingPeriod) ||
              (formik.values?.addresses[addressIndex]?.coachingPeriod
                ?.length !== 3 && {
                border: "1px solid red",
              })),
          }}
        >
          <Grid container gap={1}>
            {formik.values.addresses?.length > 0 &&
            formik.values.addresses[addressIndex].coachingPeriod?.length > 0 &&
            periodTypeIndex >= 0 &&
            formik.values.addresses[addressIndex].coachingPeriod[
              periodTypeIndex
            ]?.typeDetails?.length > 0
              ? formik.values.addresses[addressIndex].coachingPeriod[
                  periodTypeIndex
                ].typeDetails.map((period, i) => (
                  <Grid item key={i}>
                    <Chip
                      label={
                        <Box
                          display={"flex"}
                          gap={1}
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {period}
                          <CloseIcon
                            onClick={handleDelete(period)}
                            sx={{
                              fontSize: "18px",
                              strokeWidth: "2px",
                            }}
                          />
                        </Box>
                      }
                      sx={{
                        backgroundColor: "#3cb0ad",
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: 400,
                        height: "30px",
                        "&:hover": {
                          backgroundColor: "#3cb0ad",
                          color: "#fff",
                        },
                      }}
                    />
                  </Grid>
                ))
              : `Select Coaching ${periodType}`}
          </Grid>

          <KeyboardArrowDownIcon className="arrowIcon" />
        </Box>
        <Box position={"relative"}>
          <TimePeriodSelector
            formik={formik}
            timePeriodType={periodType}
            addressIndex={addressIndex}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CoachingPeriodDropdown;
