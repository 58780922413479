import { configureStore, combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./loginSlice";
// import storage from "redux-persist/lib/storage";
// import { persistStore, persistReducer } from "redux-persist";
// import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

import languageReducer from "./slice/languageSlice";
import ageGroupReducer from "./slice/ageGroupSlice";
import coachingTypeReducer from "./slice/coachingTypeSlice";
import genderReducer from "./slice/genderSlice";
import timeSlotReducer from "./slice/timeSlotSlice";
import sportReducer from "./slice/sportsSlice";
import coachReducer from "./slice/coachSlice";
import acadmyReducer from "./slice/acadmySlice";
import editProfileReducer from "./slice/editProfileSlice";
import uesersReducer from "./slice/users";


import categorySliceReducer from "./slice/categorySlice";
import offerListReducer from "./slice/offerList";
import storeListReducer from "./slice/storeList";
import createCategoryReducer from "./slice/createCategory";
import editeOfferReducer from "./slice/editeOffer";
import createPayoutReducer from "./slice/createPayout";
import createTargetingReducer from "./slice/createTargeting";
import createTrackingReducer from "./slice/createTracking";
import editeSettingReducer from "./slice/editeSetting";
import createDetailsReducer from "./slice/createDetails";

// const persistConfig = {
//   key: "root",
//   storage,
//   whitelist: ["login"],
// };

const rootReducer = (state, action) => {
  return combineReducers({
    login: loginReducer,
    languages: languageReducer,
    ageGroups: ageGroupReducer,
    coachingTypes: coachingTypeReducer,
    genders: genderReducer,
    timeSlots: timeSlotReducer,
    sports: sportReducer,
    coaches: coachReducer,
    acadmies: acadmyReducer,
    users: uesersReducer,

    categorySlice: categorySliceReducer,
    offerList: offerListReducer,
    editOffer: editeOfferReducer,
    createCategory: createCategoryReducer,
    storeList: storeListReducer,
    createDetails: createDetailsReducer,
    createPayout: createPayoutReducer,
    createTargeting: createTargetingReducer,
    createTracking: createTrackingReducer,
    editeSetting: editeSettingReducer,
    editProfile: editProfileReducer,
  })(state, action);
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
});

// export const persistor = persistStore(store);

export const ProviderWrapper = ({ children }) => {
  return (
    <Provider store={store}>
      {/* <PersistGate persistor={persistor}> */}
      {children}
      {/* </PersistGate> */}
    </Provider>
  );
};

export { store };
