export const downloadCSV = (data, filename) => {
    const csvHeaders = Object.keys(data[0]);
    const csvArray = data.map((row) =>
        csvHeaders.map((header) => row[header]).join(",")
    );
    csvArray.unshift(csvHeaders.join(","));
    const csvString = csvArray.join("\r\n");

    const a = document.createElement("a");
    a.href = `data:attachment/csv,${encodeURIComponent(csvString)}`;
    a.target = "_blank";
    a.download = `${filename}.csv`;
    document.body.appendChild(a);
    a.click();

    console.log(csvArray);
}