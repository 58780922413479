import { Typography } from "@mui/material";
import React from "react";

function InputError({ error }) {

  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 500,
        color: "red !important",
      }}
    >
      {error}
    </Typography>
  );
}

export default InputError;
