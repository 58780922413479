import { useFormik } from "formik";
const CustomFormikHook = (
  initialValues = {},
  validationSchema = {},
  onSubmit = () => {}
) => {
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values, action) => {
      console.log("Form Submitted", values);
      action.resetForm();
      onSubmit(values, action);
    },
  });
  return formik;
};
export default CustomFormikHook;
