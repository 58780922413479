import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
} from "@mui/material";

const TableHeadFill = (item) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",

        marginX: "20px",
        alignItems: "center",
      }}
    >
      {item.map((content, key) => {
        return content;
      })}
    </Box>
  );
};

const TableBodyFill = (item) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {item.map((content, key) => {
        return content;
      })}
    </Box>
  );
};

const CustomTableComponent = ({
  headingOfTable,
  bodyOfTable,
  headingRowColor,
  tableBodyColor,
  checkData,
  setCheckData,
}) => {
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      let ids = bodyOfTable.map((data) => {
        let id = data?.id
          ? data?.id[0]?.props?.children
          : data?.slug[0]?.props?.children;
        return String(id);
      });
      setCheckData?.(ids);
    } else {
      setCheckData?.([]);
    }
  };

  const handleRowCheckboxChange = (rowData) => {
    let id = rowData?.id
      ? rowData?.id[0]?.props?.children
      : rowData?.slug[0]?.props?.children;

    setCheckData?.((prevData) =>
      prevData.includes(id)
        ? prevData.filter((data) => data !== id)
        : [...prevData, id]
    );
  };

  const isSelected = (rowData) =>
    checkData?.includes(
      rowData?.id
        ? rowData?.id[0]?.props?.children
        : rowData?.slug[0]?.props?.children
    );
  return (
    <TableContainer>
      <Table>
        <TableHead
          sx={{
            backgroundColor: headingRowColor ? headingRowColor : "white",
          }}
        >
          <TableRow>
            <TableCell>
              <Checkbox
                checked={checkData?.length === bodyOfTable.length}
                onChange={handleSelectAll}
                sx={{
                  color: "white",
                  "&.Mui-checked": {
                    color: "white",
                  },
                  backgroundColor: "transparent",
                }}
              />
            </TableCell>
            {headingOfTable.map((item) => (
              <TableCell key={item.key}>
                {TableHeadFill(item.heading)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody
          sx={{
            backgroundColor: tableBodyColor ? tableBodyColor : "white",
          }}
        >
          {bodyOfTable.map((item, rowIndex) => {
            return (
              <TableRow key={rowIndex} hover>
                <TableCell>
                  <Checkbox
                    checked={isSelected(item)}
                    onClick={() => handleRowCheckboxChange(item)}
                  />
                </TableCell>
                {headingOfTable.map((content, colIndex) => (
                  <TableCell key={colIndex}>
                    {TableBodyFill(item[content.key])}
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTableComponent;

// table heading insert format--->
//  const head = [
//   {
//     heading: [{ type: "text", content: "Icon" }],
//     key: "icon",
//   },
//   {
//     heading: [
//       { type: "text", content: "Name" },
//       { type: "ButtonWithImg", content: upDownArrow },
//     ],
//     key: "name",
//   },
//   {
//     heading: [
//       { type: "text", content: "Priority" },
//       { type: "ButtonWithImg", content: upDownArrow },
//     ],
//     key: "priority",
//   },
//   {
//     heading: [
//       { type: "text", content: "Status" },
//       { type: "ButtonWithImg", content: filter },
//     ],
//     key: "status",
//   },
//   {
//     heading: [
//       { type: "text", content: "Early Sale Cashback" },
//       { type: "ButtonWithImg", content: filter },
//     ],
//     key: "earlySaleCashback",
//   },
//   {
//     heading: [
//       { type: "text", content: "Created At" },
//       { type: "ButtonWithImg", content: upDownArrow },
//     ],
//     key: "createdAt",
//   },
//   {
//     heading: [
//       { type: "text", content: "Updated At" },
//       { type: "ButtonWithImg", content: upDownArrow },
//     ],
//     key: "updatedAt",
//   },
//   { heading: [{ type: "text", content: "Actions" }], key: "actions" },
// ];

// // table body data insert format--->
// const body = [
//   {
//     icon: [{ type: "icon", content: editIcon }],
//     name: [{ type: "text", content: "MMT Priority" }],
//     priority: [{ type: "text", content: "1" }],
//     status: [{ type: "ButtonWithText", content: "Active" }],
//     earlySaleCashback: [{ type: "ButtonWithText", content: "Yes" }],
//     createdAt: [{ type: "text", content: "2021-10-20 To 12:00:00" }],
//     updatedAt: [{ type: "text", content: "2021-10-20 To 12:00:00" }],
//     actions: [{ type: "icon", content: editIcon }],
//   },
//   {
//     icon: [{ type: "icon", content: editIcon }],
//     name: [{ type: "text", content: "AJIO" }],
//     priority: [{ type: "text", content: "2" }],
//     status: [{ type: "ButtonWithText", content: "Active" }],
//     earlySaleCashback: [{ type: "ButtonWithText", content: "Yes" }],
//     createdAt: [{ type: "text", content: "2021-10-20 To 12:00:00" }],
//     updatedAt: [{ type: "text", content: "2021-10-20 To 12:00:00" }],
//     actions: [{ type: "icon", content: editIcon }],
//   },
// ];

// now pass all the data into
// <DataInsertIntoTable
// headingOfTable={head}
// bodyOfTable={body}
// headingRowColor={"teal"}
// headingTextColor={"#FFFFFF"}
// tableBodyColor={"white"}
// tableBodyTextColor={"#171A1F"}
// />;

// time and date formatt-->
// const currentTimestamp = Date.now();
// const currentDate = new Date(currentTimestamp);
// const year = currentDate.getFullYear();
// const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
// const day = currentDate.getDate().toString().padStart(2, "0");
// const hours = currentDate.getHours().toString().padStart(2, "0");
// const minutes = currentDate.getMinutes().toString().padStart(2, "0");
// const seconds = currentDate.getSeconds().toString().padStart(2, "0");
// const formattedDateTime = `${year}-${month}-${day} To ${hours}:${minutes}:${seconds}`;
