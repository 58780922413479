import * as Yup from "yup";

// E-Commerce-Pannel
export const storeDetailFormSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  title: Yup.string().required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  type: Yup.string().required("Please enter Type"),
  rtoLimit: Yup.string().required("Please enter RTO Limit"),
  rtoPeriod: Yup.string().required("Please enter RTO Period"),
  icon: Yup.mixed().required("Please upload an icon"),
  banner: Yup.mixed().required("Please upload a banner"),
  avhText: Yup.string().required("Please enter AVH Text"),
  avh: Yup.number()
    .required("Please enter AVH")
    .positive("AVH must be positive"),
  trackingTimeText: Yup.string().required("Please enter Tracking Time Text"),
  trackingTime: Yup.number()
    .required("Please enter Tracking Time")
    .positive("Tracking Time must be positive"),
  trackingTimeUnits: Yup.string().required("Please enter Tracking Time Units"),
  subCategories: Yup.string().required("Please enter Sub Categories"),
  previewUrl: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Preview URL"),
  advertiseUrl: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Advertise URL"),
  advertiseID: Yup.string().required("Please Enter Your Advertise ID"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
});

export const offerDetailFormSchema = Yup.object({
  offerName: Yup.string().required("Please enter your offer name"),

  store: Yup.string().required("Please enter store"),
  description: Yup.string().required("Please enter description"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
  icon: Yup.mixed().required("Please upload an icon"),
  offerType: Yup.string().required("Please enter RTO offertype"),
  ctaText: Yup.string().required("Please enter RTO CTA Text"),
  deepLink: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Deeplink URL"),
  price: Yup.string().required("Please enter Price"),
  oldPrice: Yup.string().required("Please enter Old Price"),
  discountPercentage: Yup.string().required("Please enter Discount Percentage"),
});

export const targetingFormSchema = Yup.object({
  gender: Yup.string().required("Please Select Your Gender"),
  ageRange: Yup.number()
    .required("Please Select Your Age Range")
    .positive("Market Place must be a Positive Number"),
  country: Yup.string().required("Please Select Your Country"),
  state: Yup.string().required("Please Select Your state"),
  city: Yup.string().required("Please Select Your City"),
  excludeState: Yup.boolean().required("Please select any option"),
  excludeCity: Yup.boolean().required("Please select any option"),
});

export const payoutFormSchema = Yup.object({
  paymentType: Yup.string().required("Please Select Your PaymentType"),
  currency: Yup.string().required("Please Select Your Currency"),
  marketplacePayout: Yup.number()
    .required("Please Enter market Place Payout")
    .positive("Market Place must be a Positive Number"),
  minOrderText: Yup.string().required("Enter Your Min Order text"),
  minOrderAmount: Yup.number()
    .required("Please Enter Min Order Amount")
    .positive("Min Order Must be Positive Number"),
});

export const onlineTrackingFormSchema = Yup.object({
  saleUrlPattern: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Sale Url Pattern"),
  orderIdRegex: Yup.string().required("Please Select Your Order Id Regex"),
  sampleOrderId: Yup.string().required("Please Select Your Sample Order Id"),
  missingSalesCount: Yup.number().required(
    "Please Select Your Missing Sales Count"
  ),
  onlineTracking: Yup.boolean().required("Please select any option"),
});

export const editCategorySchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  icon: Yup.mixed().required("Please upload an icon"),
  banner: Yup.mixed().required("Please upload a banner"),
  description: Yup.string().required("Please enter a description"),
  priority: Yup.number()
    .required("Please enter priority")
    .positive("Priority must be a positive number"),
});

export const genderCreateFormSchema = Yup.object({
  name: Yup.string().min(3).required("Please enter your name"),
  title: Yup.string().min(2).required("Please enter title"),
  description: Yup.string().required("Please enter description"),
  priority: Yup.number().min(1).required("Please enter the priority"),
});
export const languageCreateFormSchema = Yup.object({
  name: Yup.string().min(3).required("Please enter your name"),
  description: Yup.string().required("Please enter description"),
});

export const settingsFormSchema = Yup.object({
  storeName: Yup.string().min(3).required("Please enter Store Name"),
  siteEmail: Yup.string().email().required("Please enter Site Email"),
  siteCopyright: Yup.string().required("Please enter Site Copyright"),
  allowRegistration: Yup.string().required("Please select any option"),
  mainWebsite: Yup.string()
    .url("Please enter a valid URL")
    .required("Please enter Main Website Url"),
  maintenanceMode: Yup.boolean().required("Please select any option"),
});

export const ageGroupCreateFormSchema = Yup.object({
  label: Yup.string().min(3).required("Please enter your label"),
  description: Yup.string().required("Please enter description"),
});
export const createSportsSchema = Yup.object({
  name: Yup.string().required("Please enter your name"),
  title: Yup.string().required("Please enter your title"),
  icon: Yup.mixed().required("Please upload an icon"),
  banner: Yup.mixed().required("Please upload a banner"),
  description: Yup.string().required("Please enter a description"),
});
export const coachingTypeCreateFormSchema = Yup.object({
  name: Yup.string().min(3).required("Please enter your label"),
});
export const timeSlotCreateFormSchema = Yup.object({
  label: Yup.string().min(3).required("Please enter your name"),
});

export const editCoachProfileSchema = Yup.object().shape({
  fullName: Yup.string().required("* This field is mandatory"),
  sports: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* This field is mandatory"),
        label: Yup.string().required("* This field is mandatory"),
      })
    )
    .required("* This field is mandatory")
    .min(1, "* This field is mandatory"),

  addresses: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(
          "* Please enter valid address and pin code"
        ),
        pincode: Yup.string()
          .required("* Please enter valid address and pin code")
          .matches(/^\d{6}$/, "Pincode should be 6 digits"),
        city: Yup.string().required("City is required"),
        coachingPeriod: Yup.array()
          .of(
            Yup.object()
              .shape({
                type: Yup.string().required("* This field is mandatory"),
                typeDetails: Yup.array()
                  .of(Yup.string().required("* This field is mandatory"))
                  .min(1, "* This field is mandatory"),
              })
              .required("* This field is mandatory")
          )
          .required("* This field is mandatory")
          .length(3, "* This field is mandatory"),
        coachingType: Yup.string().required("* This field is mandatory"),
        slots: Yup.array()
          .of(
            Yup.object().shape({
              coachingType: Yup.string().required("* This field is mandatory"),
              label: Yup.string().required(),
              timing: Yup.object()
                .shape({
                  from: Yup.string().required(),
                  to: Yup.string().required(),
                })
                .required(),
            })
          )
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        ageGroup: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        genderTeachBy: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),
      })
    )
    .min(1, "* This field is mandatory"),

  bio: Yup.string().required("* This field is mandatory"),
  experince: Yup.number().required("* This field is mandatory"),
  groupCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  privateCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  academy: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      label: Yup.string().nullable(),
    })
  ),
});

export const editAcademyProfileSchema = Yup.object().shape({
  name: Yup.string().required("* This field is mandatory"),
  fullName: Yup.string().required("* This field is mandatory"),
  mobile: Yup.string().required("* This field is mandatory"),
  email: Yup.string().optional().email("Invalid email"),
  description: Yup.string().required("* This field is mandatory"),
  sports: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string().required("* This field is mandatory"),
        label: Yup.string().required("* This field is mandatory"),
      })
    )
    .required("* This field is mandatory")
    .min(1, "* This field is mandatory"),

  addresses: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string().required(
          "* Please enter valid address and pin code"
        ),
        pincode: Yup.string()
          .required("* Please enter valid address and pin code")
          .matches(/^\d{6}$/, "Pincode should be 6 digits"),
        city: Yup.string().required("City is required"),
        coachingPeriod: Yup.array()
          .of(
            Yup.object()
              .shape({
                type: Yup.string().required("* This field is mandatory"),
                typeDetails: Yup.array()
                  .of(Yup.string().required("* This field is mandatory"))
                  .min(1, "* This field is mandatory"),
              })
              .required("* This field is mandatory")
          )
          .required("* This field is mandatory")
          .length(3, "* This field is mandatory"),
        coachingType: Yup.string().required("* This field is mandatory"),
        slots: Yup.array()
          .of(
            Yup.object().shape({
              coachingType: Yup.string().required("* This field is mandatory"),
              label: Yup.string().required(),
              timing: Yup.object()
                .shape({
                  from: Yup.string().required(),
                  to: Yup.string().required(),
                })
                .required(),
            })
          )
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        ageGroup: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),

        genderTeachBy: Yup.array()
          .of(Yup.string().required())
          .required("* This field is mandatory")
          .min(1, "* This field is mandatory"),
      })
    )
    .min(1, "* This field is mandatory"),

  groupCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  privateCoachingPriceRange: Yup.object().shape({
    min: Yup.number().nullable(),
    max: Yup.number().nullable(),
  }),
  coaches: Yup.array().of(
    Yup.object().shape({
      id: Yup.string().nullable(),
      label: Yup.string().nullable(),
    })
  ),
});
