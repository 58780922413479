import { Box, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import React from "react";
import { timePeriods } from "./timePeriods";

function TimePeriodSelector({ formik, timePeriodType, addressIndex }) {
  const isChecked = (name) => {
    const index = getIndex(
      formik.values.addresses[addressIndex].coachingPeriod,
      timePeriodType
    );
    if (index === null || index < 0) return false;

    return formik.values.addresses[addressIndex].coachingPeriod[
      index
    ]?.typeDetails?.includes(name);
  };

  const getIndex = (arr, find) => {
    const index = arr.findIndex((tPeriod) => tPeriod.type === find);
    return index;
  };

  const addTimePeriod = (period) => {
    const index = getIndex(
      formik.values.addresses[addressIndex].coachingPeriod,
      timePeriodType
    );
    if (index >= 0) {
      const values = [...formik.values.addresses[addressIndex].coachingPeriod];
      const typeDetails = [
        ...formik.values.addresses[addressIndex].coachingPeriod[index]
          .typeDetails,
        period,
      ];
      values[index] = {
        ...values[index],
        typeDetails: typeDetails,
      };

      formik.setFieldValue(`addresses[${addressIndex}].coachingPeriod`, [
        ...values,
      ]);
    } else {
      const values = [...formik.values.addresses[addressIndex].coachingPeriod];
      formik.setFieldValue(`addresses[${addressIndex}].coachingPeriod`, [
        ...values,
        {
          type: timePeriodType,
          typeDetails: [period],
        },
      ]);
    }
  };

  const removeTimePeriod = (period) => {
    const index = getIndex(
      formik.values.addresses[addressIndex].coachingPeriod,
      timePeriodType
    );
    if (index >= 0) {
      const values = [...formik.values.addresses[addressIndex].coachingPeriod];
      const typeDetails = formik.values.addresses[addressIndex].coachingPeriod[
        index
      ].typeDetails.filter((tPeriod) => tPeriod !== period);

      values[index] = { ...values[index], typeDetails };

      if (values[index].typeDetails.length === 0) {
        values.splice(index, 1);
      }
      formik.setFieldValue(`addresses[${addressIndex}].coachingPeriod`, [
        ...values,
      ]);
    } else {
    }
  };

  const handleSelectPeriod = (e) => {
    const { name, checked } = e.target;

    if (checked) {
      addTimePeriod(name);
    } else {
      removeTimePeriod(name);
    }
  };

  return (
    <Box
      id="timePeriodSelector"
      sx={{
        display: "none",
        flexDirection: "column",
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        maxHeight: "300px",
        overflowY: "scroll",
        backgroundColor: "#fff",
        border: "1px solid rgba(84, 84, 84, 0.19)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
        borderRadius: "5px",
        padding: "20px 10px",
        zIndex: 100,
      }}
    >
      <FormGroup>
        {timePeriods[timePeriodType]?.map((timePeriod, i) => (
          <FormControlLabel
            key={i}
            label={timePeriod}
            control={
              <Checkbox
                name={timePeriod}
                checked={isChecked(timePeriod)}
                onChange={handleSelectPeriod}
                sx={{
                  "&.Mui-checked": {
                    color: "#242424",
                  },
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
}

export default TimePeriodSelector;
