export const styles = {
  adminProfileMainBox: {
    width: "50%",
    height: "60%",
    position: "absolute",
    marginTop: "18%",
    marginLeft: "59%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    border: "3px solid #FFFFFF",
    padding: "20px",
    background: "#FFFFFF",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "5%",
  },
  academyName: {
    width: "max-content",
    height: "auto",
    fontFamily: "Avenir",
    fontWeight: 800,
    fontSize: "32px",
    lineHeight: "22px",
    color: "#FFA300",
    "&:hover": {
      color: "Orange",
      transform: "scale(1.02)",
    },
  },

  location: {
    width: "max-content",
    height: "auto",
    marginTop: "6%",
    fontFamily: "Avenir",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "22px",
    color: "#242424",
  },

  tableContainer: {
    width: "90%",
    height: "60%",
    marginTop: "2%",
    marginLeft: "3%",
    borderRadius: "4px",
    backgroundColor: "#FFFFFF",
    border: "3px solid #F2F2F2",
  },
  tableHead: {
    background: "#F2F2F2",
  },
  tableHeadCell: {
    fontFamily: "Avenir",
    fontWeight: "800",
    fontSize: "14px",
    lineHeight: "13.2px",
    letterSpacing: "2.2px",
    color: "#3CB0AD",
  },
  superAdmin: {
    fontFamily: "Avenir",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#242424",
  },
  superAdminName: {
    fontFamily: "Avenir",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#3CB0AD",
    marginLeft: "7%",
  },
  admin: {
    fontFamily: "Avenir",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "23.1px",
    color: "#242424",
  },
  adminName: {
    fontFamily: "Avenir",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24.75px",
    color: "#3CB0AD",
    marginLeft: "15%",
  },
  tableBodyCell: {
    borderBottom: "none",
  },
  tableBodyCloseIconCell: { borderBottom: "none" },
};
