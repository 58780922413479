import React from "react";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
import NotificationOptions from "./notificationOptions";
const Notifications = () => {
  const notificationSettingsOption = [
    {
      label: "Email me whenever encounter unsual activity",
      defaultChecked: true,
    },
    {
      label: "Email me if new browser is used to sign in",
      defaultChecked: false,
    },
  ];
  const notificationNewsOption = [
    {
      label: "Notify me by email about sales and latest news",
      defaultChecked: true,
    },
    {
      label: "Email me about new features and updates",
      defaultChecked: false,
    },
    {
      label: "Email me about tips on using account",
      defaultChecked: true,
    },
  ];
  return (
    <Box sx={styles.mainBox}>
      <Box sx={{ marginBottom: "36px" }}>
        <Typography sx={styles.pageHeading}>Notifications</Typography>
        <Typography sx={styles.pageHeadingBaseText}>
          You will get only notification what have enabled.
        </Typography>
      </Box>

      <NotificationOptions
        heading={"Security Alerts"}
        notificationOption={notificationSettingsOption}
      />
      <NotificationOptions
        heading={"News"}
        notificationOption={notificationNewsOption}
      />
    </Box>
  );
};

export default Notifications;
