import React from "react";
import "./styles.css";
const Dashboard = () => {
  return (
    <div className="container">
      <h1 className="title">Welcome to Sport Jacks Dashboard</h1>
      <p className="description">
        Welcome to the Dashboard page! This page is designed to showcase the
        Dashboard related information
      </p>
    </div>
  );
};

export default Dashboard;
