export const styles = {
  container: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  exportButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  addButton: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  infoContainer: {
    display: "flex",
    marginTop: "20px",
  },
  infoText: {
    color: "#6E7787",
    marginLeft: "6px",
  },
  paginationContainer: {
    pl: "6px",
    overflowX: "auto",
    whiteSpace: "nowrap",
  },
};
