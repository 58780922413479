export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: { fontWeight: "600", fontSize: "36px", lineHeight: "48px" },
  headingButtonBox: { alignItems: "center", display: "flex" },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: { textTransform: "capitalize", fontWeight: "500" },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  settingFormMainBox: {
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
    paddingY: "50px",
    paddingX: "100px",
    boxShadow: "8px 8px 9px 0px #0000001A",
  },
  settingSwitchButton: {
    color: "primary.main",
    "& .MuiSwitch-thumb": {
      backgroundColor: "#3CB0AD",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "transparent",
      border: "1px solid #3CB0AD",
    },
  },
};
