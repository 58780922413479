import { Box, Button, IconButton, Typography, TextField } from "@mui/material";
import React, { useEffect } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "../Create/styles";
import { styled } from "@mui/system";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { coachingTypeCreateFormSchema } from "../../../../Schemas";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  CoachingTypeEditAPI,
  CoachingTypeGetByIdAPI,
} from "../../../../Redux/slice/coachingTypeSlice";

const RequiredIndicator = styled("span")({
  color: "red",
  marginRight: "2px",
});
const CoachingTypeEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const initialValues = {
    label: "",
    type: "",
    maxprice: "",
    minprice: "",
  };
  const formik = CustomFormikHook(initialValues, coachingTypeCreateFormSchema);

  const formFields = [
    { label: "Label", placeHolder: "Enter Your Label", name: "label" },
    { label: "Type", placeHolder: "Enter Your Price", name: "type" },
    {
      label: "Min Price",
      placeHolder: "Enter Your Min Price",
      name: "minprice",
    },
    {
      label: "Max Price",
      placeHolder: "Enter Your Max Price",
      name: "maxprice",
    },
  ];
  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      label: formik.values.label,
      type: formik.values.type,
      price: {
        min: formik.values.minprice,
        max: formik.values.maxprice,
      },
    };
    dispatch(
      CoachingTypeEditAPI({
        id: searchParams.get("id"),
        formdata: data,
      })
    );
  };
  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(
        CoachingTypeGetByIdAPI({ id: searchParams.get("id") })
      );
      data = data?.payload?.data?.data;
      formik.setValues({
        label: data?.label || "",
        type: data?.type || "",
        maxprice: String(data?.price?.max) || "",
        minprice: String(data?.price?.min) || "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Edit Coaching Type</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.editGenderFormMainBox}>
        {formFields.map((field, index) => {
          return (
            <Box key={index} sx={{ marginTop: "30px" }}>
              <Typography sx={styles.editGenderFormLabel}>
                <RequiredIndicator>*</RequiredIndicator>
                {field.label}
              </Typography>
              <TextField
                sx={styles.editGenderFormTextField}
                placeholder={field.placeHolder}
                name={field.name}
                value={formik.values[field.name]}
                onChange={formik.handleChange}
              />
              {formik.errors[field.name] && formik.touched[field.name] ? (
                <Typography
                  sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
                >
                  {formik.errors[field.name]}
                </Typography>
              ) : null}
            </Box>
          );
        })}

        <Box sx={styles.editGenderFormButtonMainBox}>
          <Button
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Update
          </Button>
          <Button
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CoachingTypeEditPage;
