import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGender,
  genderDeleteAPI,
  genderListAPI,
  genderStatusUpdateAPI,
} from "../../../../Redux/slice/genderSlice";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { downloadCSV } from "../../../../utils/utils";
import { genderStatusUpdate } from "../../../../Redux/slice/genderSlice";

const GenderListPage = () => {
  const [genders, setGenders] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [keyOfSort, setKeyOfSort] = useState("updatedAt");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);
  const head = tableHead;
  const genderGroupData = useSelector((state) => state?.genders?.data);

  const handleExport = () => {
    const csvData = genderGroupData?.map((row) => ({
      id: row?._id,
      name: row?.name,
      title: row?.title,
      description: row?.description,
      priority: row?.priority,
      createdAt: row?.createdAt,
      updatedAt: row?.updatedAt,
    }));
    downloadCSV(csvData, "genderGroupData");
  };

  const handleDelete = async () => {
    for (let id of selectedItems) {
      dispatch(genderDeleteAPI({ id }));
      dispatch(deleteGender({ id }));
    }
    setSelectedItems([]);
  };


  useEffect(() => {
    const handleStatus = (id) => (event) => {
      dispatch(
        genderStatusUpdateAPI({ id, body: { active: event?.target?.checked } })
      );
      dispatch(genderStatusUpdate({ id, active: event?.target?.checked }));
    };
    if (genderGroupData) {
      let arr = genderGroupData?.map((gender) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  checked={gender?.active}
                  onChange={handleStatus(gender?._id)}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          id: [{ type: "text", content: gender?._id }],
          label: [{ type: "text", content: gender?.label }],
          title: [{ type: "text", content: gender?.title }],
          description: [{ type: "text", content: gender?.description }],
          priority: [{ type: "text", content: gender?.priority }],
          createdAt: [{ type: "text", content: gender?.createdAt }],
          updatedAt: [{ type: "text", content: gender?.updatedAt }],
        };
      });
      setGenders(arr);
      setLoading(false);
    }
  }, [dispatch, genderGroupData]);
  
  
  useEffect(() => {
    dispatch(genderListAPI());
  }, [dispatch]);


  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!genders || genders.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }
  const sortedBody = genders.slice().sort((a, b) => {
    const nameA = a[keyOfSort].content;
    const nameB = b[keyOfSort].content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Genders</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box sx={styles.pagesDetailMainBox}>
            <Typography>
              <span style={{ color: "#171A1F", fontWeight: "700" }}>
                {genders.length}
              </span>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>
                in total
              </span>
            </Typography>

            <Typography sx={{ marginLeft: "70px" }}>
              <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
              <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
              <span style={styles.pagesDetailText}>to</span>
              <span style={styles.pagesDetailNumber}>
                {endIndex < totalItems ? endIndex : totalItems}
              </span>
              <span style={styles.pagesDetailText}>of</span>
              <span style={styles.pagesDetailNumber}>{genders.length}</span>
              <span style={styles.pagesDetailText}>results</span>
            </Typography>
          </Box>
          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          editGender={true}
          checkData={selectedItems}
          setCheckData={setSelectedItems}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default GenderListPage;
