import { Box, Typography } from "@mui/material";
import React from "react";
import LeftIconInput from "./LeftIconInput";
import InputError from "./inputError";

function YourExperience({ formik }) {
  return (
    <Box marginTop={"30px"}>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Enter your experience in years
      </Typography>
      <LeftIconInput
        name="experince"
        type="number"
        label={"Enter number of years"}
        formik={formik}
      />
      {formik.touched.experince && formik.errors.experince && (
        <InputError
          error={formik.errors.experince || "* This field is mandatory"}
        />
      )}
    </Box>
  );
}

export default YourExperience;
