import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { SPORT_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  categories: [],
  isError: false,
};

export const sportsListAPI = createAsyncThunk("sportsListAPI", async () => {
  try {
    const response = await instance.get(SPORT_URL.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const sportsCategoriesAPI = createAsyncThunk("sportsCategoriesAPI", async () => {
  try {
    const response = await instance.get(SPORT_URL.CATEGORIES);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const SportsGetByIdAPI = createAsyncThunk(
  "SportsGetById",
  async (data) => {
    try {
      const response = await instance.get(SPORT_URL.GET(data.id));
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sportsCreateAPI = createAsyncThunk(
  "sportsCreate",
  async (formdata) => {
    try {
      const response = await instance.post(SPORT_URL.CREATE, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const SportsEditAPI = createAsyncThunk(
  "sportsEdit",
  async (data) => {
    try {
      const response = await instance.put(
        SPORT_URL.UPDATE(data.id),
        data.formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sportsStatusUpdateAPI = createAsyncThunk(
  "SportsStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(
        SPORT_URL.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const sportDeleteAPI = createAsyncThunk(
  "sportDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(SPORT_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const sportsList = createSlice({
  name: "sportsList",
  initialState,
  reducers: {
    sportStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
    deleteSport: (state, action) => {
      let data = state.data?.filter((item) => item._id !== action.payload.id);
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sportsListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(sportsListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list;
    });
    builder.addCase(sportsListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    })
    builder.addCase(sportsCategoriesAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.categories = action?.payload?.list;
    });
  },
});
export const { sportStatusUpdate, deleteSport } = sportsList.actions;
export default sportsList.reducer;
