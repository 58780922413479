import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { COACH_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const coachListAPI = createAsyncThunk("coachListAPI", async () => {
  try {
    const response = await instance.get(COACH_URL.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return { error: error.message };
  }
});

export const coachStatusUpdateAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.put(COACH_URL.UPDATE_STATUS(data.id), data.body);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return { error: error.message };
    }
  }
)

export const coachDeleteAPI = createAsyncThunk(
  "coachStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.delete(COACH_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return { error: error.message };
    }
  }
)

const coachList = createSlice({
  name: "coachList",
  initialState,
  reducers: {
    deleteCoach: (state, action) => {
      state.data = state.data.map((coach) => {
        if (coach._id !== action.payload.id) return coach;

        return {
          ...coach,
          status: 'deleted',
        };
      });
    },
    updateCoachStatus: (state, action) => {
      state.data = state.data.map((coach) =>
        coach._id === action.payload.id
          ? { ...coach, live: action.payload.status }
          : coach
      );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(coachListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(coachListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.list;
    });
    builder.addCase(coachListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { deleteCoach, updateCoachStatus } = coachList.actions;
export default coachList.reducer;
