import React from "react";
import { Box, Typography } from "@mui/material";
import CustomTextFieldComponent from "../../../../Components/CustomTextField";

const OfferFormField = ({
  label,
  TextFieldRequired = true,
  component,
  value,
  handleChange,
  name,
  errors,
  touched,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {label}
        </Typography>
      </Box>
      {TextFieldRequired ? (
        <CustomTextFieldComponent
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              borderWidth: "1px",
              height: "45px",
            },
          }}
          name={name}
          value={value}
          onChange={handleChange}
        />
      ) : (
        component
      )}
    </Box>
  );
};

export default OfferFormField;
