export const styles = {
  mainBox: {
    paddingX: "25px",
    paddingY: "20px",
    backgroundColor: "#FFFFFF",
    flexgrow: "1",
  },
  pageHeading: {
    color: "#364A63",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "40px",
  },
  pageHeadingBaseText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "23px",
  },
  notificationOptionsMainBox: { marginBottom: "48px" },
  notificationOptionsHeading: {
    fontSize: "24px",
    fontWeight: "600",
    color: "#323842",
    lineHeight: "48px",
  },
  notificationOptionsHeadingBaseText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#242424",
    lineHeight: "24px",
    marginBottom: "24px",
  },
  notificationOptionsSwitchMainBox: { marginY: "6px", display: "block" },
  notificationOptionsSwitchLabelText: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#242424",
    lineHeight: "12px",
  },
};
