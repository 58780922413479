import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
// import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
// import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SJlogo from "../Assets/Logos/SJlogo.svg";
import PrimarySearchAppBar from "./Appbar";
// import RightArrowIcon from "../Assets/Icons/Drawer/RightArrowIcon.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { menuItems, subMenuItems } from "./seidebarData";

const drawerWidth = 307;
const logoContainerHeight = 140;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(12)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(14)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: drawerWidth, // Always set marginLeft to drawerWidth
  width: `calc(100% - ${theme.spacing(20)})`,
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth + 50}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Layout({ children, ...rest }) {
  const [open, setOpen] = useState(true);
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null);
  const [selectedSubMenuInnerItem, setSelectedSubMenuInnerItem] =
    useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSubMenueClicked = (subItem) => {
    if (subItem.subMenu) {
      setSelectedSubMenuInnerItem((prev) =>
        prev === subItem.label ? null : subItem.label
      );
    } else {
      navigate(subItem.link);
      setSelectedSubMenuInnerItem(null);
    }
  };

  const CustomListItemIcon = ({ icon: Icon, open, style }) => (
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: open ? 3 : "auto",
        justifyContent: "center",
      }}
    >
      <Icon
        sx={{
          color: "primary.dark",
          width: "24px",
          height: "24px",

          ...style,
        }}
      />
    </ListItemIcon>
  );

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F2F2F2",
      }}
    >
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          margin: "20px",
        }}
      >
        <PrimarySearchAppBar />
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            borderRight: "none",
            boxShadow: "8px 4px 23.2px 0px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <DrawerHeader>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", height: logoContainerHeight }}
          >
            <Box
              sx={{
                margin: "auto",
                display: "grid",
                placeItems: "center",
                gridAutoFlow: "column",
                gap: "10px",
              }}
            >
              {open ? (
                <>
                  <img
                    src={SJlogo}
                    alt="SJ Logo"
                    style={{ width: "200px", opacity: open ? 1 : 0 }}
                  />
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // width: "100%",

                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Box>
          </Box>
        </DrawerHeader>

        <List
          sx={{
            overflowX: "hidden",
            overflowY: "auto",
            scrollbarWidth: "none",
            "::-webkit-scrollbar": {
              display: "none" /* Safari and Chrome */,
            },
          }}
        >
          {menuItems.map((itemObj, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                display: "block",
              }}
            >
              <Box
                sx={{
                  background: location.pathname.startsWith(itemObj.path)
                    ? "#3CB0AD"
                    : "inherit",
                  margin: "10px 20px",
                  borderRadius: "16px",
                  "& :hover": {
                    borderRadius: "16px",
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 3,
                    py: 1.6,
                  }}
                  onClick={() => {
                    if (itemObj.moreOptions) {
                      // setSubMenu(!subMenu);
                      setSelectedSubMenuItem((prev) =>
                        prev === itemObj.label ? null : itemObj.label
                      );
                    }
                    navigate(itemObj.link);
                  }}
                >
                  <CustomListItemIcon
                    icon={itemObj.icon}
                    open={open}
                    style={{
                      color: location.pathname.startsWith(itemObj.path)
                        ? "primary.light"
                        : "primary.dark",
                    }}
                  />
                  <ListItemText
                    primary={itemObj?.label}
                    sx={{
                      opacity: open ? 1 : 0,
                      color: location.pathname.startsWith(itemObj.path)
                        ? "primary.light"
                        : "primary.dark",
                    }}
                  />
                  {itemObj.moreOptions &&
                    open &&
                    (selectedSubMenuItem === itemObj.label ? (
                      <ExpandMoreIcon
                        sx={{
                          color: location.pathname.startsWith(itemObj.path)
                            ? "primary.light"
                            : "primary.dark",
                        }}
                      />
                    ) : (
                      <ChevronRightIcon
                        sx={{
                          marginRight: "-12px",
                          color: location.pathname.startsWith(itemObj.path)
                            ? "primary.light"
                            : "primary.dark",
                        }}
                      />
                    ))}
                </ListItemButton>
              </Box>
              {selectedSubMenuItem === itemObj.label && (
                <List>
                  {subMenuItems[itemObj.label]?.map((subItem, subIndex) => {
                    let path = location.pathname
                      ?.split("/")
                      .splice(0, 3)
                      .join("/");
                    return (
                      <ListItem
                        key={subIndex}
                        disablePadding
                        sx={{
                          display: "block",
                          marginY: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            background:
                              path === subItem.link
                                ? "rgba(60, 176, 173, 0.2)"
                                : "inherit",
                            margin: "0px 20px",
                            borderRadius: "16px",
                            "& :hover": {
                              borderRadius: "16px",
                            },
                          }}
                        >
                          <ListItemButton
                            sx={{
                              minHeight: 48,
                              justifyContent: open ? "initial" : "center",
                              px: 3,
                              py: 1.6,
                            }}
                            onClick={() => handleSubMenueClicked(subItem)}
                          >
                            <CustomListItemIcon
                              icon={subItem.icon}
                              open={open}
                              style={{
                                margin: "0px 4px",
                                color:
                                  path === subItem.link
                                    ? "primary.main"
                                    : "primary.dark",
                              }}
                            />
                            <ListItemText
                              primary={subItem?.label}
                              sx={{
                                fontSize: "12px",
                                opacity: open ? 1 : 0,
                                color:
                                  path === subItem.link
                                    ? "primary.main"
                                    : "primary.dark",
                              }}
                            />
                            {subItem.moreOptions &&
                              open &&
                              (selectedSubMenuInnerItem === subItem.label ? (
                                <ExpandLessIcon
                                  sx={{
                                    color:
                                      path === subItem.link
                                        ? "primary.main"
                                        : "primary.dark",
                                  }}
                                />
                              ) : (
                                <ChevronRightIcon
                                  sx={{
                                    color:
                                      path === subItem.link
                                        ? "primary.main"
                                        : "primary.dark",
                                  }}
                                />
                              ))}
                          </ListItemButton>
                        </Box>
                        {selectedSubMenuInnerItem === subItem.label && (
                          <List>
                            {subItem.subMenu.map((subMenuItem, subIndex) => (
                              <ListItem
                                key={subIndex}
                                disablePadding
                                sx={{
                                  display: "block",
                                }}
                              >
                                <Box
                                  sx={{
                                    margin: "0px 60px",
                                    "& :hover": {
                                      borderRadius: "16px",
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <ListItemButton
                                    sx={{
                                      minHeight: 48,
                                      justifyContent: open
                                        ? "initial"
                                        : "center",
                                      px: 3,
                                      py: 1.6,
                                    }}
                                    onClick={() => {
                                      navigate(subMenuItem.link);
                                    }}
                                  >
                                    <CustomListItemIcon
                                      icon={subMenuItem.icon}
                                      open={open}
                                      style={{
                                        color:
                                          location.pathname === subMenuItem.link
                                            ? "primary.main"
                                            : "primary.dark",
                                      }}
                                    />
                                    <ListItemText
                                      primary={subMenuItem?.label}
                                      sx={{
                                        fontSize: "10px",
                                        opacity: open ? 1 : 0,
                                        color:
                                          location.pathname === subMenuItem.link
                                            ? "primary.main"
                                            : "primary.dark",
                                      }}
                                    />
                                    {subMenuItem.moreOptions &&
                                      open &&
                                      (selectedSubMenuInnerItem ===
                                      subMenuItem.label ? (
                                        <ExpandLessIcon
                                          sx={{
                                            color:
                                              location.pathname ===
                                              subMenuItem.link
                                                ? "primary.main"
                                                : "primary.dark",
                                          }}
                                        />
                                      ) : (
                                        <ChevronRightIcon
                                          sx={{
                                            color:
                                              location.pathname ===
                                              subMenuItem.link
                                                ? "primary.main"
                                                : "primary.dark",
                                          }}
                                        />
                                      ))}
                                  </ListItemButton>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        )}
                      </ListItem>
                    );
                  })}
                </List>
              )}
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
