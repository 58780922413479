import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SportsIcon from "@mui/icons-material/Sports";
import StadiumIcon from "@mui/icons-material/Stadium";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export const subMenuItems = {
    "E-commerce Panel": [
        {
            label: "Store",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/store/editstore",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/store/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Offer",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/offer/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/offer/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Category",
            subMenu: [
                {
                    label: "Create",
                    link: "/ecommercepanel/category/editcategory",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/ecommercepanel/category/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
    ],
    "Ecom-data": [],
    "website-data": [
        {
            label: "Language",
            link: "/master/language",
            subMenu: [
                { label: "Create", link: "/master/language/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/language/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Age Group",
            link: "/master/agegroup",
            subMenu: [
                { label: "Create", link: "/master/agegroup/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/agegroup/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Coaching Type",
            link: "/master/coachingtype",
            subMenu: [
                { label: "Create", link: "/master/coachingtype/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/coachingtype/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Gender",
            link: "/master/gender",
            subMenu: [
                { label: "Create", link: "/master/gender/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/gender/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Time Slot",
            link: "/master/timeslot",
            subMenu: [
                { label: "Create", link: "/master/timeslot/create", icon: AddIcon },
                {
                    label: "List",
                    link: "/master/timeslot/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
        {
            label: "Sport",
            link: "/master/sport",
            subMenu: [
                {
                    label: "Create",
                    link: "/master/sport/create",
                    icon: AddIcon,
                },
                {
                    label: "List",
                    link: "/master/sport/list",
                    icon: FormatListBulletedIcon,
                },
            ],
            icon: HomeIcon,
            moreOptions: true,
        },
    ],
};
export const menuItems = [
    {
        label: "Dashboard",
        link: "/dashboard",
        path: "/dashboard",
        icon: HomeIcon,
        moreOptions: false,
    },
    {
        label: "E-commerce Panel",
        path: "/ecommercepanel",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["E-commerce Panel"],
    },
    {
        label: "Ecom-data",
        path: "/ecom-data",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["Master"],
    },
    {
        label: "website-data",
        path: "/master",
        icon: ShoppingCartIcon,
        moreOptions: true,
        subMenu: subMenuItems["Master"],
    },
    {
        label: "Coaching",
        link: "/coaching",
        path: "/coaching",
        icon: SportsIcon,
        moreOptions: false,
    },
    {
        label: "Academy",
        link: "/academy",
        path: "/academy",
        icon: StadiumIcon,
        moreOptions: false,
    },
    {
        label: "Users",
        link: "/users",
        path: "/users",
        icon: PeopleAltIcon,
        moreOptions: false,
    },
    {
        label: "User Profile",
        link: "/userprofile",
        path: "/userprofile",
        icon: AccountCircleIcon,
        moreOptions: false,
    },
    {
        label: "Settings",
        link: "/settings",
        path: "/settings",
        icon: SettingsIcon,
        moreOptions: false,
    },
];