import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import InputError from "./inputError";
import { ageGroupListAPI } from "../../Redux/slice/ageGroupSlice";
import { useDispatch } from "react-redux";
// import { getAgeGroupList } from "@/utils/masterAPI";

function SelectAgeGroup({ formik, addressIndex }) {
  const [ageGroupList, setAgeGroupList] = useState([]);
  const dispatch = useDispatch();

  const isChecked = (id) => {
    return formik.values.addresses[addressIndex].ageGroup?.includes(id);
  };

  const handleAgeGroup = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      const values = [...formik.values.addresses[addressIndex].ageGroup, name];
      formik.setFieldValue(`addresses[${addressIndex}].ageGroup`, values);
    } else {
      const values = formik.values.addresses[addressIndex].ageGroup.filter(
        (ageG) => name !== ageG
      );
      formik.setFieldValue(`addresses[${addressIndex}].ageGroup`, values);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await dispatch(ageGroupListAPI()).unwrap();
        setAgeGroupList(response?.list);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <Box sx={{ marginTop: "45px", marginBottom: "24px" }}>
      <Typography
        sx={{
          fontWeight: 600,
        }}
      >
        Select Age Groups
      </Typography>
      <Typography sx={{ marginBottom: "24px" }}>
        *You can select multiple age groups
      </Typography>
      <FormGroup>
        {ageGroupList?.map((ageG, i) => (
          <FormControlLabel
            key={i}
            onChange={handleAgeGroup}
            label={ageG.label?.toLowerCase()}
            sx={{ textTransform: "capitalize" }}
            control={
              <Checkbox
                name={ageG._id}
                checked={isChecked(ageG._id)}
                sx={{
                  "&.Mui-checked": {
                    color: "#242424",
                  },
                }}
              />
            }
          />
        ))}
      </FormGroup>
      {(formik.touched.addresses?.length > 0 &&
        formik.errors.addresses?.length > 0 &&
        formik.touched.addresses[addressIndex]?.ageGroup &&
        formik.errors.addresses[addressIndex]?.ageGroup) ||
        formik.values.addresses[addressIndex]?.ageGroup === null ||
        (formik.values.addresses[addressIndex]?.ageGroup?.length === 0 && (
          <InputError error={"* This field is mandatory"} />
        ))}
    </Box>
  );
}

export default SelectAgeGroup;
