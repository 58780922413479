import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Pages/Login";
import PrivateRoute from "./privateRoutes";
import Dashboard from "../Pages/Dashboard";
import CoachingPage from "../Pages/Coaching";
import AcademyPage from "../Pages/Academy";
import SettingsPage from "../Pages/Settings/index";
import UsersList from "../Pages/Users";
// E-CommercePannel
import StorePage from "../Pages/ECommercePannel/Store/StoreList/index";
import EditStore from "../Pages/ECommercePannel/Store/EditStore/index";
import OfferList from "../Pages/ECommercePannel/Offer/OfferList";
import CategoryList from "../Pages/ECommercePannel/Category/CategoryList";
import EditCategory from "../Pages/ECommercePannel/Category/EditCategory";
import CreateOfferDetails from "../Pages/ECommercePannel/Offer/EditOffer";

// -----------------

import UserProfile from "../Pages/UserProfile";
import GenderEditPage from "../Pages/WebsiteData/Gender/Edit";
import GenderCreatePage from "../Pages/WebsiteData/Gender/Create";
import GenderListPage from "../Pages/WebsiteData/Gender/List";

import AgeCreatePage from "../Pages/WebsiteData/AgeGroup/Create";
import AgeListPage from "../Pages/WebsiteData/AgeGroup/List";
import AgeGroupEditPage from "../Pages/WebsiteData/AgeGroup/Edit";

import LanguageCreatePage from "../Pages/WebsiteData/Language/Create";
import LanguageListPage from "../Pages/WebsiteData/Language/List";
import LanguageEditPAge from "../Pages/WebsiteData/Language/Edit";

import CoachingTypeCreatePage from "../Pages/WebsiteData/CoachingType/Create";
import CoachingTypeListPage from "../Pages/WebsiteData/CoachingType/List";
import CoachingTypeEditPage from "../Pages/WebsiteData/CoachingType/Edit";

import TimeSlotCreatePage from "../Pages/WebsiteData/TimeSlot/Create";
import TimeSlotListPage from "../Pages/WebsiteData/TimeSlot/List";
import TimeSlotEditPage from "../Pages/WebsiteData/TimeSlot/Edit";

import SportCreatePage from "../Pages/WebsiteData/Sport/Create";
import SportListPage from "../Pages/WebsiteData/Sport/List";
import SportsEditPage from "../Pages/WebsiteData/Sport/Edit";

import EditCoachProfile from "../Pages/Coaching/EditCoachProfile";
import EditAcademyProfile from "../Pages/Academy/EditAcademyProfile";

const routes = [
  {
    path: "/",
    element: <Login />,
    isPrivate: false,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    isPrivate: true,
  },

  // E-Commerce_Pannel first
  {
    path: "/ecommercepanel/store",
    element: <StorePage />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/store/list",
    element: <StorePage />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/store/editstore",
    element: <EditStore />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/offer/list",
    element: <OfferList />,
    isPrivate: true,
  },

  {
    path: "/ecommercepanel/offer/create",
    element: <CreateOfferDetails />,
    isPrivate: true,
  },

  {
    path: "/ecommercepanel/category/list",
    element: <CategoryList />,
    isPrivate: true,
  },
  {
    path: "/ecommercepanel/category/editcategory",
    element: <EditCategory />,
    isPrivate: true,
  },

  {
    path: "/coaching",
    element: <CoachingPage />,
    isPrivate: true,
  },
  {
    path: "/coaching/editprofile",
    element: <EditCoachProfile />,
    isPrivate: true,
  },
  {
    path: "/master/language/create",
    element: <LanguageCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/create",
    element: <AgeCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/create",
    element: <CoachingTypeCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/list",
    element: <CoachingTypeListPage />,
    isPrivate: true,
  },
  {
    path: "/master/coachingtype/list/edit",
    element: <CoachingTypeEditPage />,
    isPrivate: true,
  },

  {
    path: "/master/timeslot/create",
    element: <TimeSlotCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/create",
    element: <SportCreatePage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/list",
    element: <SportListPage />,
    isPrivate: true,
  },
  {
    path: "/master/sport/list/edit",
    element: <SportsEditPage />,
    isPrivate: true,
  },
  {
    path: "/academy",
    element: <AcademyPage />,
    isPrivate: true,
  },
  // {
  //   path: "/academy/academyprofile",
  //   element: <AcademyProfile />,
  //   isPrivate: true,
  // },
  {
    path: "/academy/editprofile",
    element: <EditAcademyProfile />,
    isPrivate: true,
  },
  {
    path: "/settings",
    element: <SettingsPage />,
    isPrivate: true,
  },
  {
    path: "/users",
    element: <UsersList />,
    isPrivate: true,
  },

  {
    path: "/master/gender/list",
    element: <GenderListPage />,
    isPrivate: true,
  },
  {
    path: "/master/gender/list/edit",
    element: <GenderEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/list",
    element: <AgeListPage />,
    isPrivate: true,
  },
  {
    path: "/master/agegroup/list/edit",
    element: <AgeGroupEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/language/list",
    element: <LanguageListPage />,
    isPrivate: true,
  },
  {
    path: "/master/language/list/edit",
    element: <LanguageEditPAge />,
    isPrivate: true,
  },

  {
    path: "/master/timeslot/list",
    element: <TimeSlotListPage />,
    isPrivate: true,
  },
  {
    path: "/master/timeslot/list/edit",
    element: <TimeSlotEditPage />,
    isPrivate: true,
  },
  {
    path: "/master/gender/create",
    element: <GenderCreatePage />,
    isPrivate: true,
  },
  {
    path: "/userprofile",
    element: <UserProfile />,
    isPrivate: true,
  },
];

const AllRoutes = () => {
  return (
    <Router>
      <Routes>
        {routes &&
          routes.map((route, index) => {
            if (route.path === "/") {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            } else {
              return (
                <Route
                  exact
                  key={index}
                  path={route.path}
                  element={<PrivateRoute>{route.element}</PrivateRoute>}
                />
              );
            }
          })}
      </Routes>
    </Router>
  );
};

export default AllRoutes;
