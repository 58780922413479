import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../Components/CustomPagination";
import DataInsertIntoTable from "../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  coachDeleteAPI,
  coachListAPI,
  coachStatusUpdateAPI,
  deleteCoach,
  updateCoachStatus,
} from "../../Redux/slice/coachSlice";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { downloadCSV } from "../../utils/utils";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const CoachingPage = () => {
  const [coaches, setCoaches] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItems, setSelectedItems] = useState([]);

  const coachesState = useSelector((state) => state.coaches?.data);
  const itemsPerPage = 10;
  const head = tableHead;

  const handleCoachProfilePage = (coach) => {
    window.open(
      `https://sportjacks.com/profile/${coach?.slug}?type=coach&id=${coach?._id}`,
      "_blank"
    );
  };

  const handleExport = () => {
    const getAddreStr = (address) =>
      `${address?.address} ${address?.city}; ${address?.state}`;

    const csv = coachesState.map((row) => {
      return {
        id: row?._id,
        slugUser: row?.slug,
        slugCoach: row?.coachProfile?.slug,
        name: row?.fullName,
        phoneNumber: row?.coachProfile?.phoneNumber,
        email: row?.email,
        sports: row.coachProfile?.sports?.map((sport) => sport.name).join("; "),
        totalNoBookings: "NA",
        profileVisits: "NA",
        address: getAddreStr(row?.address),
        addresses: row?.coachProfile?.addresses
          ?.map((address) => getAddreStr(address))
          .join("; "),
        status: row.coachProfile?.status,
        rating: "4.5",
      };
    });
    downloadCSV(csv, "coaches");
  };

  const handleDelete = async () => {
    for (let id of selectedItems) {
      dispatch(deleteCoach({ id: id }));
      dispatch(coachDeleteAPI({ id: id }));
    }
  };

  useEffect(() => {
    const handleStatus = (id) => (event) => {
      dispatch(updateCoachStatus({ id, status: event?.target?.checked }));
      dispatch(
        coachStatusUpdateAPI({ id, body: { status: event?.target?.checked } })
      );
    };
    if (coachesState) {
      let arr = coachesState?.map((coach) => {
        return {
          actions: [
            {
              content: (
                <Switch
                  onChange={handleStatus(coach?._id)}
                  checked={coach?.live}
                />
              ),
            },
            {
              content: (
                <VisibilityOutlinedIcon
                  sx={{ color: "primary.main" }}
                  onClick={() => handleCoachProfilePage(coach)}
                />
              ),
            },
            { type: "ButtonWithImg", content: editIcon },
          ],
          coach: [
            {
              type: "verifiedDetails",
              content: {
                img: editIcon,
                name: coach?.fullName,
                phoneNumber: coach?.phoneNumber,
                verified: true,
              },
            },
          ],
          id: [{ type: "text", content: coach?._id }],
          sports: [
            {
              type: "text",
              content: coach?.sports[0]?.metaTitle || "NA",
            },
          ],
          totalNoBookings: [{ type: "text", content: "NA" }],
          emailID: [{ type: "email", content: coach?.email }],
          profileVisits: [{ type: "text", content: "NA" }],
          address: [{ type: "text", content: coach?.address[0]?.city || "NA" }],
          status: [{ type: "status", content: coach?.status|| (coach.deleted && "deleted") || (coach?.live ? "active" : "inactive") || "NA" }],
          rating: [{ type: "rating", content: "4.5" }],
        };
      });
      setCoaches(arr);
      setLoading(false);
    }
  }, [coachesState, dispatch]);

  useEffect(() => {
    dispatch(coachListAPI());
  }, [dispatch]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (!coaches || coaches.length === 0) {
    return (
      <Typography
        sx={{
          display: "flex",
          justifyContent: "center",
          color: "red",
          fontFamily: "Avenir",
          fontSize: "40px",
          alignItems: "center",
        }}
      >
        No data available
      </Typography>
    );
  }
  const sortedBody = coaches.slice()?.sort((a, b) => {
    const nameA = a[keyOfSort]?.content;
    const nameB = b[keyOfSort]?.content;

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Coach List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox} onClick={handleExport}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {coaches.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{coaches.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>
          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          coachList={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default CoachingPage;
