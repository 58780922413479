import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box } from "@mui/material";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clsx from "clsx";
import styles from "./timeInput.module.scss";
import moment from "moment";

function TimeInput({ name, setTime, handleBlur, value, disabled, timeLimit }) {
  const handleTimeChange = (date) => {
    let time = moment(date).format("HH:mm");
    setTime(time, name);
  };

  const convertTo12Hour = (time) => {
    if (!time) return "";
    let [hours, minutes] = time.split(":");
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const min = moment(timeLimit?.from, "H:mm").toDate();
  const max = moment(timeLimit?.to, "H:mm").toDate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        border: "1px solid rgba(238, 238, 238, 0.72)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        marginTop: "16px",
        marginBottom: "10px",
        padding: "0 10px",
        fontSize: "16px",
        fontWeight: 500,
        color: "#cccccc",
        height: "50px",
        maxWidth: "130px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <ReactDatePicker
        showIcon
        showTimeSelect
        showPopperArrow
        showTimeSelectOnly
        toggleCalendarOnIconClick
        // disabledKeyboardNavigation
        autoComplete="off"
        onKeyDown={(e) => e.preventDefault()}
        name={name}
        disabled={disabled}
        onBlur={handleBlur}
        value={convertTo12Hour(value)}
        onChange={(date) => {
          handleTimeChange(date);
        }}
        minTime={min}
        maxTime={max}
        timeFormat="hh:mm aa"
        dateFormat="hh:mm aa"
        timeIntervals={60}
        placeholderText={`--:-- --`}
        icon={<KeyboardArrowDownIcon />}
        className={clsx(styles.timeInput)}
        calendarIconClassname={clsx(styles.calendarIcon)}
        wrapperClassName={clsx(styles.timeInputWrapper)}
        timeClassName={clsx(styles.timeInputTime)}
        popperClassName={clsx(styles.timePekerPopper)}
      />
    </Box>
  );
}

export default TimeInput;
