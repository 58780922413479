export const styles = {
  mainBox: {
    paddingX: "25px",
    paddingY: "20px",
    backgroundColor: "#FFFFFF",
    flexgrow: "1",
    height:"100%",
  },
  pageHeading: {
    color: "#364A63",
    fontWeight: "700",
    fontSize: "28px",
    lineHeight: "40px",
  },
  pageHeadingBaseText: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "23px",
  },
  fieldsMainBox: {
    backgroundColor: "#F2F2F2",
    paddingX: "30px",
    paddingY: "8px",
    width: "700px",
  },
  fieldHeading: {
    color: "primary.main",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "23px",
    letterSpacing: "2.2px",
  },
  fieldsLabel: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#8094AE",
    lineHeight: "23px",
    textWrap: "wrap",
  },
  fieldsValue: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#526484",
    lineHeight: "24.75px",
    textWrap: "wrap",
    width: "250px",
    display: "flex",
    justifyContent: "flex-start",
  },
};
