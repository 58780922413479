import React from "react";
import { Box } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function AddButtonWithLabel({ onClick, label }) {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "15px",
        margin: "15px 0",
        fontSize: "12px",
        fontWeight: 500,
        cursor: "pointer",
      }}
    >
      <AddCircleOutlineIcon />
      {label}
    </Box>
  );
}

export default AddButtonWithLabel;
