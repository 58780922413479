import React from "react";
import { Box, Typography } from "@mui/material";
import PersonalInformationFields from "./personalInformationFields";
import { styles } from "./styles";

const PersonalInformation = () => {
  const basicInformation = [
    {
      label: "Full Name",
      value: "Abu Bin Ishtiyak",
    },
    { label: "Display Name", value: "Ishtiyak" },
    { label: "Email", value: "info@softnio.com" },
    { label: "Phone Number", value: "" },
    { label: "Date of Birth", value: "29 Feb, 1986" },
    { label: "Nationality", value: "Canadian" },
    { label: "Address", value: "2337 Kildeer Drive, Kentucky Canada" },
  ];
  return (
    <Box
      sx={styles.mainBox}
    >
      <Box sx={{ marginBottom: "40px" }}>
        <Typography
          sx={styles.pageHeading}
        >
          Personal Information
        </Typography>
        <Typography
          sx={styles.pageHeadingBaseText}
        >
          Basic info, like your name and address, that you use on Nio Platform.
        </Typography>
      </Box>
      <PersonalInformationFields
        heading={"BASICS"}
        information={basicInformation}
      />
    </Box>
  );
};

export default PersonalInformation;
