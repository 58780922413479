export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: { fontWeight: "600", fontSize: "36px", lineHeight: "48px" },
  headingButtonBox: { alignItems: "center", display: "flex" },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: { textTransform: "capitalize", fontWeight: "500" },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  userProfileMainBox: {
    display: "flex",
    marginY: "50px",
    backgroundColor: "#FFFFFF",
    ml: "6px",
  },
  sideBarFirstMainBox: {
    display: "inline-flex",
    paddingX: "25px",
    paddingY: "25px",
    borderBottom: "1px solid #DBDFEA",
    borderRight: "1px solid #DBDFEA",
  },
  userProfileAvatar: {
    backgroundColor: "primary.main",
    fontWeight: "700",
    fontSize: "18px",
    
  },
  userProfileName: {
    fontWeight: "800",
    color: "#364A63",
    fontSize: "20px",
    lineHeight: "22px",
  },
  userProfileEmail: {
    color: "#8094AE",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
  },
  userProfileMenuIcon: { color: "#242424", width: "22px", height: "22px" },
  sideBarSeconMainBox: {
    minHeight: "375px",
    paddingY: "10px",
    borderBottom: "1px solid #DBDFEA",
    borderRight: "1px solid #DBDFEA",
  },
  listItemButtonMainBox: {
    margin: "2px 0px",
    borderRadius: "16px",
    "& :hover": {
      borderRadius: "16px",
    },
  },
  listItemButton: {
    minHeight: 48,
    justifyContent: "initial",
    px: 4,
    py: 1.6,
  },
  sideBarThirdMainBox: {
    minHeight: "400px",
    paddingY: "25px",
    paddingX: "35px",
    borderRight: "1px solid #DBDFEA",
  },
  sideBarThirdMainBoxThemeHeading: {
    fontWeight: "500",
    fontSize: "15px",
    color: "primary.main",
    lineHeight: "21px",
    marginBottom: "10px",
  },
  sideBarThirdMainBoxText: {
    fontWeight: "400",
    fontSize: "15px",
    color: "primary.dark",
    lineHeight: "21px",
  },
};
