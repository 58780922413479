import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { Button, Typography } from "@mui/material";

const SecuritySettings = () => {
  return (
    <div>
      <Box sx={{ marginLeft: 4 }}>
        <Typography
          sx={{
            fontWeight: "700",
            fontSize: "28px",
            lineHeight: "40px",
          }}
        >
          Security Settings
        </Typography>
        <Typography
          sx={{ fontWeight: "500", fontSize: "14px", lineHeight: "23px" }}
        >
          You can save your all activity logs including unusual activity
          detected.
        </Typography>
      </Box>

      <Card
        variant="outlined"
        sx={{
          marginTop: "10px",
          paddingLeft: "10px",
          maxWidth: 720,
          marginLeft: 5,
          height: "auto",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography sx={{ mb: 1, margin: "0" }}>
              <h2 sx={{ size: "24px" }}>Save my Activity Logs</h2>
              <p style={{ margin: "0", marginTop: "0.5rem" }}>
                You can save your all activity logs, including unusual activity
                detected.
              </p>
            </Typography>
            <Typography>
              <Switch checked />
            </Typography>
          </Stack>
        </Box>
        <Divider />

        <Box sx={{ p: 1 }}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>
              <h2 sk={{ size: "24px" }}>Change Password</h2>
              <p style={{ margin: "0", marginTop: "0.5rem" }}>
                Set a unique password to protect your account.
              </p>
              <Typography>
                Last changed
                <Button
                  variant="contained"
                  sx={{ marginLeft: 12, color: "white" }}
                >
                  Change Password
                </Button>
              </Typography>
            </Typography>
          </Stack>
        </Box>
        <Divider />

        <Box sx={{ p: 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Typography sx={{}}>
              <h2 style={{ display: "inline" }}>2 Factor Auth</h2>
              <Button
                variant="contained"
                size="small"
                sx={{
                  fontSize: "10px",
                  marginLeft: 2,
                  color: "white",
                  backgroundColor: "#FFA300",
                }}
              >
                Enable
              </Button>
            </Typography>
            <Typography>
              <Button
                variant="contained"
                size="small"
                sx={{ marginRight: 10, marginTop: 0, color: "white" }}
              >
                Disable
              </Button>
            </Typography>
          </Stack>
          <p>
            Secure your account with 2FA security. When it is activated you
            <br />
            will need to enter not only your password but also a special code
            <br />
            using the app. You can receive this code by in the mobile app.
          </p>
        </Box>
      </Card>
    </div>
  );
};

export default SecuritySettings;
