export const styles = {
  root: {},
  mainContainer: {
    position: "relative",
    maxWidth: "1060px",
    width: "60vw",
    maxHeight: "744px",
    height: "80dvh",
    background: "primary.light",
    margin: "auto",
    marginTop: "15dvh",
    boxShadow: "0px 4px 4px 0px rgba(36, 36, 36, 0.10)",
    padding: "25px",
    display: "flex",
    borderRadius: "10px",
  },
  imageContainer: {
    position: "relative",
    background: "#D9D9D9",
    height: "100%",
  },
  logo: {
    position: "absolute",
    top: "0",
    left: "0",
  },
};
