import upDownArrow from "../../Assets/Icons/Drawer/upDownArrow.svg";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";

export const styles = {
  mainBox: {
    pl: "6px",
    marginBottom: "10px",
  },
  headingMainBox: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headingTextBox: { fontWeight: "600", fontSize: "36px", lineHeight: "48px" },
  headingButtonBox: { alignItems: "center", display: "flex" },
  filterButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": {
      backgroundColor: "primary.dark",
      color: "primary.light",
    },
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  filterTypoText: { textTransform: "capitalize", fontWeight: "500" },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": {
      backgroundColor: "primary.dark",
      color: "primary.light",
    },
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: { textTransform: "capitalize", fontWeight: "500" },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": {
      backgroundColor: "primary.dark",
      color: "primary.light",
    },
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  pagesDetailMainBox: { display: "flex", marginTop: "20px" },
  pagesDetailText: { color: "#6E7787", marginLeft: "4px" },
  pagesDetailNumber: {
    color: "#171A1F",
    fontWeight: "500",
    marginLeft: "4px",
  },
};

export const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "actions",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ACADEMY" }],
    key: "academy",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "SLUG" }],
    key: "slug",
    sort: false,
  },
  {
    heading: [
      { type: "text", content: "TOTAL NO. BOOKINGS" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "totalNoBookings",
    sort: true,
  },
  {
    heading: [
      { type: "text", content: "PROFILE VISITS" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "profileVisits",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "E-MAIL ID" }],
    key: "emailID",
    sort: false,
  },

  {
    heading: [{ type: "text", content: "CITY / COUNTRY" }],
    key: "country",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ADMIN" }],
    key: "admin",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "STATUS" }],
    key: "status",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "RATING" }],
    key: "rating",
    sort: false,
  },
];

export const tableBody = [
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 1",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.5" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 2",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 3",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "3.5" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 4",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 5",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 6",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 7",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 8",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "xyz@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Active" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 9",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "02" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Inactive" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 10",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "10" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Pending" }],
    rating: [{ type: "rating", content: "4.2" }],
  },
  {
    academy: [
      {
        type: "verifiedDetails",
        content: {
          img: editIcon,
          name: "Academy 11",
          phoneNumber: "9525354515",
          verified: true,
        },
      },
    ],
    id: [{ type: "text", content: "10" }],
    totalNoBookings: [{ type: "text", content: "02" }],
    profileVisits: [{ type: "text", content: "02" }],
    emailID: [{ type: "email", content: "abc@gmail.com" }],
    country: [{ type: "text", content: "Brodipet/Gutur/A.P" }],
    admin: [{ type: "text", content: "Ravi Sharma" }],
    status: [{ type: "status", content: "Pending" }],
    rating: [{ type: "rating", content: "2.1" }],
  },
];
