import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import PriceINRInput from "./priceINRInput";
import { useState } from "react";
import { useSelector } from "react-redux";

function CoachingPrices({ formik, addresses }) {
  const { data: coachingTypes } = useSelector(
    (state) => state.coachingTypes
  );
  const [isSelectedGroup, setIsSelectedGroup] = useState(false);
  const [isSelectedPrivate, setIsSelectedPrivate] = useState(false);
  const [isSelectedBoth, setIsSelectedBoth] = useState(false);

  const clearState = () => {
    setIsSelectedGroup(false);
    setIsSelectedPrivate(false);
    setIsSelectedBoth(false);
  };

  const clearFormikState = () => {
    if (!isSelectedBoth && !isSelectedGroup && !isSelectedPrivate) return;
    if (isSelectedBoth) return;
    if (!isSelectedGroup) {
      formik.setFieldValue("groupCoachingPriceRange.min", null);
      formik.setFieldValue("groupCoachingPriceRange.max", null);
    }
    if (!isSelectedPrivate) {
      formik.setFieldValue("privateCoachingPriceRange.min", null);
      formik.setFieldValue("privateCoachingPriceRange.max", null);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      clearState();
      let coachingType = coachingTypes;

      let both = coachingType?.find((cType) => cType.type === "both")?._id;
      let group = coachingType?.find((cType) => cType.type === "groupCoaching")?._id;
      let privateType = coachingType?.find(
        (cType) => cType.type === "privateCoaching"
      )?._id;

      let addrs = addresses || [];
      
      [...addrs]?.forEach((add) => {
        if (String(add?.coachingType) === String(group)) {
          setIsSelectedGroup(true);
        }
        if (String(add?.coachingType) === String(privateType)) {
          setIsSelectedPrivate(true);
        }
        if (String(add?.coachingType) === String(both)) {
          setIsSelectedBoth(true);
        }
      });
      clearFormikState();
    };
    if (coachingTypes) fetchData();
  }, [addresses, coachingTypes]);

  return (
    <Grid
      id="pricing"
      container
      gap={"20px"}
      sx={{
        fontSize: "16px",
        fontWeight: 500,
        color: "#242424",
      }}
    >
      {(isSelectedBoth || isSelectedGroup) && (
        <Grid item marginTop={"30px"}>
          <Typography
            sx={{
              color: "#242424",
              fontWeight: 600,
            }}
          >
            Enter price for Group Coaching
          </Typography>
          <Typography
            sx={{
              color: "#242424",
              marginBottom: "20px",
            }}
          >
            *Enter an estimated price range
          </Typography>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            gap={{ xs: "0px", sm: "16px" }}
            sx={{
              width: "100%",
              marginLeft: { xs: "0px", sm: "0px" },
            }}
          >
            <PriceINRInput
              name={`min`}
              formikField="groupCoachingPriceRange"
              formik={formik}
            />
            to
            <PriceINRInput
              name={`max`}
              formikField="groupCoachingPriceRange"
              formik={formik}
            />
          </Grid>
        </Grid>
      )}
      {(isSelectedBoth || isSelectedPrivate) && (
        <Grid item marginTop={"30px"}>
          <Typography
            sx={{
              color: "#242424",
              fontWeight: 600,
            }}
          >
            Enter price for Private Coaching
          </Typography>
          <Typography
            sx={{
              color: "#242424",
              marginBottom: "20px",
            }}
          >
            *Enter an estimated price range
          </Typography>
          <Grid
            container
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            gap={{ xs: "0px", sm: "16px" }}
            sx={{
              width: "100%",
              marginLeft: { xs: "0px", sm: "0px" },
            }}
          >
            <PriceINRInput
              name={`min`}
              formik={formik}
              formikField="privateCoachingPriceRange"
            />
            to
            <PriceINRInput
              name={`max`}
              formikField="privateCoachingPriceRange"
              formik={formik}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default CoachingPrices;
