import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DownArrowIcon from "../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../Components/CustomPagination";
import DataInsertIntoTable from "../../Components/CustomTableComponent/dataInsertIntoTable";
import { styles, tableHead } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  academyDeleteAPI,
  academyStatusUpdateAPI,
  acadmyAPI,
  deleteAcademy,
  updateAcademyStatus,
} from "../../Redux/slice/acadmySlice";
import editIcon from "../../Assets/Icons/Drawer/editIcon.svg";
import Admin from "./SuperAcademyLogin";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { downloadCSV } from "../../utils/utils";

const AcademyPage = () => {
  const [acadmies, setAcadmies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const academyData = useSelector((state) => state.acadmies?.data);

  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [keyOfSort, setKeyOfSort] = useState("profileVisits");
  const [currentPage, setCurrentPage] = useState(1);
  const head = tableHead;
  const sortedBody = acadmies?.sort((a, b) => {
    const nameA = a[keyOfSort][0]?.content?.toUpperCase();
    const nameB = b[keyOfSort][0]?.content?.toUpperCase();

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });
  
  const totalItems = sortedBody?.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody?.slice(startIndex, endIndex);
 
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleAcademyProfilePage = (academy) => {
    window.open(
      `https://sportjacks.com/profile/${academy?.slug}?type=academy&id=${academy?._id}`,
      "_blank"
    );
  };

  const handleExport = () => {
    const getAddreStr = (address) =>
      `${address?.address} ${address?.city}; ${address?.state}`;

    const csv = academyData.map((row) => {
      return {
        id: row?._id,
        slug: row?.slug,
        name: row?.name,
        phoneNumber: row?.phoneNumber,
        email: row?.email,
        sports: row?.sports?.map((sport) => sport.name).join("; "),
        totalNoBookings: "NA",
        profileVisits: "NA",
        address: getAddreStr(row?.address),
        status: row.coachProfile?.status,
        rating: "4.5",
      };
    });
    downloadCSV(csv, "academies");
  };

  const handleDelete = async () => {
    for (let slug of selectedItems) {
      dispatch(deleteAcademy({ slug: slug }));
      dispatch(academyDeleteAPI({ slug: slug }));
    }
  };

  useEffect(() => {
    const handleStatus = (slug) => (event) => {
      dispatch(updateAcademyStatus({ slug, status: event?.target?.checked }));
      dispatch(
        academyStatusUpdateAPI({
          slug,
          body: { status: event?.target?.checked },
        })
      );
    };
    if (!academyData) return;
    let arr = academyData?.map((academy) => {
      return {
        actions: [
          {
            content: (
              <Switch
                onChange={handleStatus(academy?.slug)}
                checked={academy?.active}
              />
            ),
          },
          {
            content: (
              <VisibilityOutlinedIcon
                sx={{ color: "primary.main" }}
                onClick={() => handleAcademyProfilePage(academy)}
              />
            ),
          },
          { type: "ButtonWithImg", content: editIcon },
        ],
        academy: [
          {
            type: "verifiedDetails",
            content: {
              img: editIcon,
              name: academy?.name,
              phoneNumber: academy?.mobile,
              verified: true,
            },
          },
        ],
        slug: [{ type: "text", content: academy?.slug }],
        totalNoBookings: [{ type: "text", content: "NA" }],
        profileVisits: [{ type: "text", content: "NA" }],
        emailID: [{ type: "email", content: academy?.email || "NA" }],
        country: [{ type: "text", content: academy?.address?.city }],
        admin: [{ type: "text", content: academy?.adminCoach?.fullName }],
        status: [
          {
            type: "status",
            content: academy?.deleted ? "deleted" : academy?.active ? "active" : "inactive",
          },
        ],
        rating: [{ type: "rating", content: "4.5" }],
      };
    });
    setAcadmies(arr);
    setLoading(false);
  }, [academyData, dispatch]);

  useEffect(() => {
    dispatch(acadmyAPI());
  }, [dispatch]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  const handleOpenAdminModal = (adminDetails) => {
    setSelectedAdmin(adminDetails);
    setOpenAdminModal(true);
  };

  const handleCloseAdminModal = () => {
    setOpenAdminModal(false);
  };
  // const handleAcademyClick = (academy) => {
  //   handleOpenAdminModal(academy.admin[0].content);
  // };
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Academy List</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.filterButtonBox}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText} onClick={handleExport}>
                Export
              </Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {acadmies.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

 <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{acadmies.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography> 
         
          <Box mt={2} textAlign="center" ml="auto">
            {selectedItems.length > 0 ? (
              <IconButton
                onClick={handleDelete}
                aria-label="delete"
                sx={{ color: "red" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          pl: "6px",
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        <DataInsertIntoTable
          checkData={selectedItems}
          setCheckData={setSelectedItems}
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
          setHandleOpen={setOpenAdminModal}
          setHandleAcademyClick={setSelectedAdmin}
          modalOpen={true}
          academyList={true}
        />
      </Box>
      <Box
        sx={{
          pl: "6px",
          marginTop: "20px",
        }}
      >
        <CustomPagination
          type={"text"}
         totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
      <Modal
        open={openAdminModal}
        onClose={handleCloseAdminModal}
        aria-labelledby="modal-admin-title"
        aria-describedby="modal-admin-description"
      >
        <Box>{selectedAdmin && <Admin adminDetails={selectedAdmin} />}</Box>
      </Modal>
    </>
  );
};

export default AcademyPage;
