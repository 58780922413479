import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { CATEGORYLIST_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const fetchCategory = createAsyncThunk("fetchCategory", async () => {
  const response = await instance.get(
    `${CATEGORYLIST_URL}`
  );
  console.log(response.data);
  return response.data;
});

const categorySlice = createSlice({
  name: "categorySlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchCategory.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export default categorySlice.reducer;
