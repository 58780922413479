import upDownArrow from "../../../../Assets/Icons/Drawer/upDownArrow.svg";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Switch from "@mui/material/Switch";

export const tableHead = [
  {
    heading: [{ type: "text", content: "Actions" }],
    key: "actions",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "ID" }],
    key: "id",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Offer Name" }],
    key: "offerName",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Store Name" }],
    key: "storeName",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Offer Description" }],
    key: "offerDescription",
    sort: false,
  },
  {
    heading: [
      { type: "text", content: "Priority" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "priority",
    sort: true,
  },
  {
    heading: [{ type: "text", content: "Coupon" }],
    key: "coupon",
    sort: false,
  },
  {
    heading: [{ type: "text", content: "Type" }],
    key: "type",
    sort: false,
  },
  {
    heading: [
      { type: "text", content: "Created At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "createdAt",
    sort: true,
  },
  {
    heading: [
      { type: "text", content: "Updated At" },
      { type: "ButtonWithImg", content: upDownArrow },
    ],
    key: "updatedAt",
    sort: true,
  },
];

export const tableBody = [
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer1" }],
    storeName: [{ type: "text", content: "store1" }],
    offerDescription: [{ type: "text", content: "description1" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "2580" }],
    offerName: [{ type: "text", content: "offer2" }],
    storeName: [{ type: "text", content: "store2" }],
    offerDescription: [{ type: "text", content: "description2" }],
    priority: [{ type: "text", content: "108" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer3" }],
    storeName: [{ type: "text", content: "store3" }],
    offerDescription: [{ type: "text", content: "description3" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer4" }],
    storeName: [{ type: "text", content: "store4" }],
    offerDescription: [{ type: "text", content: "description4" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer5" }],
    storeName: [{ type: "text", content: "store5" }],
    offerDescription: [{ type: "text", content: "description5" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer6" }],
    storeName: [{ type: "text", content: "store6" }],
    offerDescription: [{ type: "text", content: "description6" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
  {
    actions: [
      { content: <Switch /> },
      {
        content: <VisibilityOutlinedIcon sx={{ color: "primary.main" }} />,
      },
      { type: "ButtonWithImg", content: editIcon },
    ],
    id: [{ type: "text", content: "258" }],
    offerName: [{ type: "text", content: "offer7" }],
    storeName: [{ type: "text", content: "store7" }],
    offerDescription: [{ type: "text", content: "description7" }],
    priority: [{ type: "text", content: "1085" }],
    coupon: [{ type: "ButtonWithText", content: "ABSENT" }],
    type: [{ type: "text", content: "FEATURED" }],
    createdAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
    updatedAt: [{ type: "text", content: "2023-12-28T17:23:33.7" }],
  },
];

export const styles = {
  container: {
    pl: "6px",
    marginBottom: "10px",
  },
  header: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: "36px",
    lineHeight: "48px",
  },
  buttonContainer: {
    alignItems: "center",
    display: "flex",
  },
  button: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "140px",
    justifyContent: "space-between",
    pl: "12px",
    pr: "20px",
    py: "10px",
    mx: "20px",
  },
  filterTypoText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  exportButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    "&:hover": "none",
    width: "114px",
    justifyContent: "space-between",
    py: "10px",
    pl: "16px",
    pr: "16px",
    mx: "20px",
  },
  exportTypoText: {
    textTransform: "capitalize",
    fontWeight: "500",
  },
  addIconButtonBox: {
    color: "primary.light",
    backgroundColor: "primary.dark",
    justifyContent: "center",
    paddingX: "10px",
    paddingY: "10px",
    borderRadius: "4px",
    ml: "12px",
  },
  pagesDetailMainBox: {
    display: "flex",
    marginTop: "20px",
  },
  pagesDetailText: {
    color: "#6E7787",
    marginLeft: "4px",
  },
  pagesDetailNumber: {
    color: "#171A1F",
    fontWeight: "500",
    marginLeft: "4px",
  },
  createFormBox: {
    marginY: "50px",
    paddingY: "20px",
  },
};
