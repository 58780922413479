import React from "react";
import { Box, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { styles } from "./styles";
import AccountActivityTable from "./accountActivityTable";

const AccountActivity = () => {
  const rows = [
    {
      ip: "192.149.122.128",
      time: "Nov 20, 2019, 11:34 PM",
      browser: "Chrome on Windows",
    },
    {
      browser: "Chrome",
      ip: "192.149.122.128",
      time: "11:34 PM",
      icon: <CloseRoundedIcon />,
    },
  ];

  const headingOfTable = ["BROWSER", "IP", "TIME"];

  return (
    <Box sx={styles.mainBox}>
      <Box sx={{ marginBottom: "36px" }}>
        <Typography sx={styles.pageHeading}>Login Activity</Typography>
        <Typography sx={styles.pageHeadingBaseText}>
          Here is your last 20 login activities log.
        </Typography>
      </Box>
      <AccountActivityTable
        headingOfTable={headingOfTable}
        bodyOfTable={rows}
      />
    </Box>
  );
};

export default AccountActivity;
