import React from "react";
// import { Provider } from "react-redux";
import { ProviderWrapper } from "./Redux";
// import store from "./Redux";
import { ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { Toaster } from "sonner";
import AllRoutes from "./Routes";
import styles from "./app.css";
// import SJLogo from "../../Assets/Logos/SJlogo.svg";
const App = () => {
  return (
    <ProviderWrapper>
      <ThemeProvider theme={theme}>
        <div className="App" style={styles}>
          <Toaster style={{ top: "100px" }} richColors position="top-right" />
          <AllRoutes />
        </div>
      </ThemeProvider>
    </ProviderWrapper>
  );
};
export default App;
