import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { COACHING_TYPE_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const coachingTypeListAPI = createAsyncThunk(
  "coachingTypeListAPI",
  async () => {
    try {
      const response = await instance.get(COACHING_TYPE_URL.LIST);
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const CoachingTypeGetByIdAPI = createAsyncThunk(
  "CoachingTypeGetById",
  async (data) => {
    try {
      const response = await instance.get(COACHING_TYPE_URL.GET(data.id));
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const coachingTypeCreateAPI = createAsyncThunk(
  "coachingTypeCreate",
  async (formdata) => {
    try {
      const response = await instance.post(
        COACHING_TYPE_URL.CREATE,
        formdata
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const CoachingTypeEditAPI = createAsyncThunk(
  "coachingTypeEdit",
  async (data) => {
    try {
      const response = await instance.put(
        COACHING_TYPE_URL.UPDATE(data.id),
        data.formdata
      );

      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const coachingTypeStatusUpdateAPI = createAsyncThunk(
  "coachingTypeStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(COACHING_TYPE_URL.UPDATE_STATUS(data.id), data.body);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const coachingTypeDeleteAPI = createAsyncThunk(
  "coachingTypeDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(COACHING_TYPE_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const coachingTypeList = createSlice({
  name: "coachingTypeList",
  initialState,
  reducers: {
    coachingTypeStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
    deleteCoachingType: (state, action) => {
      state.data = state.data.filter((item) => item._id !== action.payload.id);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(coachingTypeListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(coachingTypeListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list;
    });
    builder.addCase(coachingTypeListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export const { coachingTypeStatusUpdate, deleteCoachingType } = coachingTypeList.actions;
export default coachingTypeList.reducer;
