import React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";

const buttonStyle = {
  color: "#526484",
  border: "1px solid #E5E9F2",
  fontFamily: "Avenir",
  fontSize: "13px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "16px",
  backgroundColor: "white",
  "&:hover": { border: "1px solid #526484" },
};

const PageNumber = styled('span')({
  margin: '0 10px',
  fontFamily: 'Avenir',
  fontSize: '13px',
  fontWeight: '400',
  lineHeight: '16px',
  color: '#526484',
});

const CustomPagination = ({
  type,
  totalPages,
  currentPage,
  setCurrentPage,
}) => {
  if (totalPages === 1) return <></>; // Do not render pagination for single page

  const handlePrevPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage === totalPages) return;
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      {type === "arrow" ? (
        <>
          <Button
            sx={buttonStyle}
            onClick={handlePrevPage}
            variant="outlined"
          >
            left
          </Button>
          <PageNumber>{currentPage}</PageNumber>
          <Button
            sx={buttonStyle}
            onClick={handleNextPage}
            variant="outlined"
          >
            right
          </Button>
        </>
      ) : (
        <>
          <Button
            sx={buttonStyle}
            variant="outlined"
            onClick={handlePrevPage}
          >
            Prev
          </Button>
          <PageNumber>{currentPage}</PageNumber>
          <Button
            sx={buttonStyle}
            variant="outlined"
            onClick={handleNextPage}
          >
            Next
          </Button>
        </>
      )}
    </>
  );
};

export default CustomPagination;
