import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { ACADEMY_URLS, COACH_URL, COCHING_PERIODS_ENUMS, GET_COACH_PROFILE, SPORTSLIST_URL, UPDATE_COACH_PROFILE } from "../../constants";

export const getCoachProfile = createAsyncThunk(
    "editProfile/getCoachProfile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.get(COACH_URL.GET(data.id));
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
);

export const getAcademyProfile = createAsyncThunk(
    "editProfile/getAcademyProfile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.get(ACADEMY_URLS.GET(data.slug));
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
);

export const updateCoachprofile = createAsyncThunk(
    "editProfile/updateCoachprofile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.put(COACH_URL.UPDATE(data.id), data.body);
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
);

export const updateAcademyprofile = createAsyncThunk(
    "editProfile/updateAcademyprofile",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.put(ACADEMY_URLS.UPDATE(data.slug), data);
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
);

export const sportsSearch = createAsyncThunk(
    "editProfile/sportsSearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.get(SPORTSLIST_URL, { params: { search: data } });
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
)

export const academySearch = createAsyncThunk(
    "editProfile/academySearch",
    async (data, { rejectWithValue }) => {
        try {
            const response = await instance.get(SPORTSLIST_URL, { params: { search: data } });
            return response;
        } catch (err) {
            console.log(err.message);
            return rejectWithValue(err);
        }
    }
);

const initialState = {
    coach: null,
    loading: false,
    error: null,
    status: null,
    sports: [],
    academies: [],
    academy: null
}

const editProfile = createSlice({
    name: "editProfile",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getCoachProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCoachProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.coach = action.payload?.data?.data;
            })
            .addCase(getCoachProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                console.error("API Error:", action.payload);
            })
            .addCase(getAcademyProfile.pending, (state) => {
                state.loading = true;
            })
            .addCase(getAcademyProfile.fulfilled, (state, action) => {
                state.loading = false;
                state.academy = action.payload?.data?.data;
            })
            .addCase(getAcademyProfile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                console.error("API Error:", action.payload);
            })
            .addCase(updateCoachprofile.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateCoachprofile.fulfilled, (state, action) => {
                state.loading = false;
                state.status = action.payload?.data?.message;
            })
            .addCase(updateCoachprofile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                console.error("API Error:", action.payload);
            })
            .addCase(sportsSearch.pending, (state) => {
                state.loading = true;
            })
            .addCase(sportsSearch.fulfilled, (state, action) => {
                state.loading = false;
                state.sports = action.payload?.data?.list;
            })
            .addCase(sportsSearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                console.error("API Error:", action.payload);
            })
            .addCase(academySearch.pending, (state) => {
                state.loading = true;
            })
            .addCase(academySearch.fulfilled, (state, action) => {
                state.loading = false;
                state.academies = action.payload?.data?.list;
            })
            .addCase(academySearch.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                console.error("API Error:", action.payload);
            })
    },
});

export default editProfile.reducer;