/*import React from "react";
import CustomTableComponent from ".";
import { Typography, Box, Avatar, Link, IconButton } from "@mui/material";
import CustomButtonComponent from "../CustomButton";
import Button from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";

const DataInsertIntoTable = ({
  headingOfTable,
  bodyOfTable,
  headingRowColor,
  tableBodyColor,
  headingTextColor,
  tableBodyTextColor,
  setKeyOfSort,
  setHandleOpen,
  setHandleAcademyClick,
  coachHandle,
  coachHaldleOpen,
  modalOpen = false,
  coachList = false,
  academyList=false,
  editLanguage = false,
  ageGroup = false,
  editGender = false,
  timeSlot = false,
  coachingType = false,
  sportsCategory = false,
  sports = false,
  checkData,
  setCheckData,
}) => {
  const navigate = useNavigate();

  const editCoachProfile = (item) => {
    navigate(`/coaching/editprofile?id=${item.id[0]?.content}`);
  };

  const editAcademyProfile = (item) => {
    navigate(`/academy/editprofile?slug=${item.slug[0]?.content}`);
  };
  const editLanguagePage = (item) => {
    navigate(`/master/language/list/edit?id=${item.id[0]?.content}`);
  };
  const editAgeGroupPage = (item) => {
    navigate(`/master/agegroup/list/edit?id=${item.id[0]?.content}`);
  };
  const editGenderPage = (item) => {
    navigate(`/master/gender/list/edit?id=${item.id[0]?.content}`);
  };
  const editTimeSlotPage = (item) => {
    navigate(`/master/timeslot/list/edit?id=${item.id[0]?.content}`);
  };
  const editCoachingTypePage = (item) => {
    navigate(`/master/coachingtype/list/edit?id=${item.id[0]?.content}`);
  };
  const editSportsCategoryPage = (item) => {
    navigate(`/master/sportcategory/list/edit?id=${item.id[0]?.content}`);
  };
  const editSportsPage = (item) => {
    navigate(`/master/sport/list/edit?id=${item.id[0]?.content}`);
  };

  const head2 = headingOfTable.map((item) => {
    return {
      heading: item.heading.map((innerItem) => {
        switch (innerItem.type) {
          case "text":
            return (
              <Typography
                sx={{
                  fontWeight: "800",
                  fontSize: "14px",
                  color: headingTextColor ? headingTextColor : "#171A1F",
                  fontStyle: "normal",
                  fontFamily: "Avenir",
                }}
                key={item.key}
              >
                {innerItem?.content}
              </Typography>
            );
          case "ButtonWithImg":
            return (
              <>
                <CustomButtonComponent
                  variant="text"
                  onClick={() => {
                    if (item.sort) setKeyOfSort(item.key);
                  }}
                  sx={{ padding: "0px", margin: "0px" }}
                  key={item.key}
                >
                  <img alt="img_here" src={innerItem?.content} />
                </CustomButtonComponent>
              </>
            );
          case "ButtonWithText":
            return <Button key={item.key}>{innerItem?.content}</Button>;
          case "icon":
            return <img alt="img_here" src={innerItem?.content} />;
          default:
            return null;
        }
      }),
      key: item.key,
    };
  });

  const body2 = bodyOfTable.map((item) => {
    const mapInnerItems = (innerItem) => {
      switch (innerItem.type) {
        case "text":
          return (
            <Typography
              sx={{
                fontWeight: "800",
                fontSize: "14px",
                color: tableBodyTextColor ? tableBodyTextColor : "#171A1F",
                fontStyle: "normal",
                fontFamily: "Avenir",
              }}
            >
              {innerItem?.content}
            </Typography>
          );
        case "ButtonWithText":
          return (
            <CustomButtonComponent key={item.key} variant="contained">
              {innerItem?.content}
            </CustomButtonComponent>
          );
        case "icon":
          return <img alt="img_here" src={innerItem?.content} />;
        case "ButtonWithImg":
          return (
            <IconButton
              sx={{
                marginX: "1px",
              }}
              onClick={() => {
                if (editLanguage) editLanguagePage(item);
                if (ageGroup) editAgeGroupPage(item);
                if (coachingType) editCoachingTypePage(item);
                if (editGender) editGenderPage(item);
                if (timeSlot) editTimeSlotPage(item);
                if (sportsCategory) editSportsCategoryPage(item);
                if (sports) editSportsPage(item);
                if (coachList) editCoachProfile(item);
                if (academyList) editAcademyProfile(item);
              }}
            >
              <img alt="img_here" src={innerItem?.content} />
            </IconButton>
          );
        case "verifiedDetails":
          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (modalOpen) {
                    setHandleAcademyClick(innerItem);
                    setHandleOpen(true);
                  }
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", width: "175px" }}
                >
                  <Avatar
                    alt="Remy Sharp"
                    sx={{
                      width: "32px",
                      height: "32px",
                      bgcolor: "deepOrange[500]",
                    }}
                  />
                  <Box sx={{ marginLeft: "10px" }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                      {innerItem?.content.name}
                    </Typography>
                    <Typography sx={{ fontWeight: "100", fontSize: "12px" }}>
                      {innerItem?.content.phoneNumber}
                    </Typography>
                  </Box>
                </Box>
                {innerItem?.content.verified ? (
                  <VerifiedIcon
                    sx={{
                      color: "green",
                      width: "28px",
                      height: "28px",
                      marginLeft: "30px",
                    }}
                  />
                ) : (
                  <Box marginLeft="58px"></Box>
                )}
              </Box>
            </>
          );
        case "email":
          return (
            <Typography sx={{ color: "#1F7BC2" }}>
              <Link
                href={`mailto:${innerItem?.content}`}
                sx={{ color: "#1F7BC2", textDecoration: "underline #1F7BC2" }}
              >
                {innerItem?.content}
              </Link>
            </Typography>
          );
        case "status":
          return (
            <>
              {innerItem?.content?.toLowerCase() === "active" && (
                <Typography sx={{ color: "#1EE1B1" }}>Active</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "pending" && (
                <Typography sx={{ color: " #F8CB0E" }}>Pending</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "inactive" && (
                <Typography sx={{ color: "#EC5347" }}>Inactive</Typography>
              )}
              {innerItem?.content?.toLowerCase() === "deleted" && (
                <Typography sx={{ color: "#EC5347" }}>Deleted</Typography>
              )}
            </>
          );
        case "rating":
          return (
            <>
              {innerItem?.content < 4.5 ? (
                <Typography sx={{ color: "#F8CB0E" }}>
                  {innerItem?.content}
                </Typography>
              ) : (
                <Typography sx={{ color: "#1EE1B1" }}>
                  {innerItem?.content}
                </Typography>
              )}
            </>
          );
        default:
          return innerItem?.content;
      }
    };

    return Object.fromEntries(
      Object.entries(item).map(([key, value]) => [
        key,
        value.map(mapInnerItems),
      ])
    );
  });
  return (
    <CustomTableComponent
      checkData={checkData}
      setCheckData={setCheckData}
      headingOfTable={head2}
      bodyOfTable={body2}
      headingRowColor={headingRowColor}
      tableBodyColor={tableBodyColor}
    />
  );
};

export default DataInsertIntoTable;
*/


import React, { useState, useEffect } from "react";
import { Typography, Box, Avatar, Link, IconButton, Button } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useNavigate } from "react-router-dom";
import CustomTableComponent from ".";
import CustomButtonComponent from "../CustomButton";

const DataInsertIntoTable = ({
  headingOfTable,
  bodyOfTable,
  headingRowColor,
  tableBodyColor,
  headingTextColor,
  tableBodyTextColor,
  setKeyOfSort,
  setHandleOpen,
  setHandleAcademyClick,
  modalOpen = false,
  coachList = false,
  academyList = false,
  editLanguage = false,
  ageGroup = false,
  editGender = false,
  timeSlot = false,
  coachingType = false,
  sportsCategory = false,
  sports = false,
  checkData,
  setCheckData,
}) => {
  const navigate = useNavigate();
  const [mappedHeadings, setMappedHeadings] = useState([]);
  const [mappedBody, setMappedBody] = useState([]);

  const pageRoutes = {
    editCoachProfile: (id) => `/coaching/editprofile?id=${id}`,
    editAcademyProfile: (slug) => `/academy/editprofile?slug=${slug}`,
    editLanguagePage: (id) => `/master/language/list/edit?id=${id}`,
    editAgeGroupPage: (id) => `/master/agegroup/list/edit?id=${id}`,
    editGenderPage: (id) => `/master/gender/list/edit?id=${id}`,
    editTimeSlotPage: (id) => `/master/timeslot/list/edit?id=${id}`,
    editCoachingTypePage: (id) => `/master/coachingtype/list/edit?id=${id}`,
    editSportsCategoryPage: (id) => `/master/sportcategory/list/edit?id=${id}`,
    editSportsPage: (id) => `/master/sport/list/edit?id=${id}`,
  };

  const handleEditPageNavigation = (pageType, item) => {
    const id = item.id[0]?.content;
    const slug = item.slug[0]?.content;
    navigate(pageRoutes[pageType](id || slug));
  };

  const mapHeadings = () => headingOfTable.map((item) => ({
    heading: item.heading.map((innerItem) => {
      switch (innerItem.type) {
        case "text":
          return (
            <Typography key={item.key} sx={getHeadingTextStyles(headingTextColor)}>
              {innerItem?.content}
            </Typography>
          );
        case "ButtonWithImg":
          return (
            <CustomButtonComponent
              key={item.key}
              variant="text"
              onClick={() => item.sort && setKeyOfSort(item.key)}
              sx={{ padding: 0, margin: 0 }}
            >
              <img alt="img_here" src={innerItem?.content} />
            </CustomButtonComponent>
          );
        case "ButtonWithText":
          return <Button key={item.key}>{innerItem?.content}</Button>;
        case "icon":
          return <img alt="img_here" src={innerItem?.content} />;
        default:
          return null;
      }
    }),
    key: item.key,
  }));

  const mapBody = () => bodyOfTable.map((item) => Object.fromEntries(
    Object.entries(item).map(([key, value]) => [key, value.map(mapInnerItems(item))])
  ));

  const mapInnerItems = (item) => (innerItem) => {
    switch (innerItem.type) {
      case "text":
        return <Typography sx={getBodyTextStyles(tableBodyTextColor)}>{innerItem?.content}</Typography>;
      case "ButtonWithText":
        return <CustomButtonComponent key={item.key} variant="contained">{innerItem?.content}</CustomButtonComponent>;
      case "icon":
        return <img alt="img_here" src={innerItem?.content} />;
      case "ButtonWithImg":
        return (
          <IconButton
            sx={{ marginX: 1 }}
            onClick={() => handleEditButtonClick(item)}
          >
            <img alt="img_here" src={innerItem?.content} />
          </IconButton>
        );
      case "verifiedDetails":
        return renderVerifiedDetails(innerItem);
      case "email":
        return (
          <Typography sx={{ color: "#1F7BC2" }}>
            <Link href={`mailto:${innerItem?.content}`} sx={getEmailLinkStyles()}>
              {innerItem?.content}
            </Link>
          </Typography>
        );
      case "status":
        return renderStatus(innerItem);
      case "rating":
        return renderRating(innerItem);
      default:
        return innerItem?.content;
    }
  };

  const handleEditButtonClick = (item) => {
    if (editLanguage) handleEditPageNavigation('editLanguagePage', item);
    if (ageGroup) handleEditPageNavigation('editAgeGroupPage', item);
    if (coachingType) handleEditPageNavigation('editCoachingTypePage', item);
    if (editGender) handleEditPageNavigation('editGenderPage', item);
    if (timeSlot) handleEditPageNavigation('editTimeSlotPage', item);
    if (sportsCategory) handleEditPageNavigation('editSportsCategoryPage', item);
    if (sports) handleEditPageNavigation('editSportsPage', item);
    if (coachList) handleEditPageNavigation('editCoachProfile', item);
    if (academyList) handleEditPageNavigation('editAcademyProfile', item);
  };

  const renderVerifiedDetails = (innerItem) => (
    <Box sx={{ display: "flex", alignItems: "center" }} onClick={() => handleVerifiedDetailsClick(innerItem)}>
      <Box sx={{ display: "flex", alignItems: "center", width: 175 }}>
        <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32, bgcolor: "deepOrange[500]" }} />
        <Box sx={{ marginLeft: 1 }}>
          <Typography sx={{ fontWeight: 500, fontSize: 16 }}>{innerItem?.content.name}</Typography>
          <Typography sx={{ fontWeight: 100, fontSize: 12 }}>{innerItem?.content.phoneNumber}</Typography>
        </Box>
      </Box>
      {innerItem?.content.verified ? (
        <VerifiedIcon sx={{ color: "green", width: 28, height: 28, marginLeft: 3 }} />
      ) : (
        <Box marginLeft={7}></Box>
      )}
    </Box>
  );

  const handleVerifiedDetailsClick = (innerItem) => {
    if (modalOpen) {
      setHandleAcademyClick(innerItem);
      setHandleOpen(true);
    }
  };

  const renderStatus = (innerItem) => (
    <Typography sx={{ color: getStatusColor(innerItem?.content) }}>{innerItem?.content}</Typography>
  );

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "active": return "#1EE1B1";
      case "pending": return "#F8CB0E";
      case "inactive": return "#EC5347";
      case "deleted": return "#EC5347";
      default: return "#171A1F";
    }
  };

  const renderRating = (innerItem) => (
    <Typography sx={{ color: innerItem?.content < 4.5 ? "#F8CB0E" : "#1EE1B1" }}>{innerItem?.content}</Typography>
  );

  const getHeadingTextStyles = (color) => ({
    fontWeight: 800,
    fontSize: "14px",
    color: color || "#171A1F",
    fontStyle: "normal",
    fontFamily: "Avenir",
  });

  const getBodyTextStyles = (color) => ({
    fontWeight: 800,
    fontSize: "14px",
    color: color || "#171A1F",
    fontStyle: "normal",
    fontFamily: "Avenir",
  });

  const getEmailLinkStyles = () => ({
    color: "#1F7BC2",
    textDecoration: "underline #1F7BC2",
  });

  useEffect(() => {
    setMappedHeadings(mapHeadings());
    setMappedBody(mapBody());
  }, [headingOfTable, bodyOfTable, headingTextColor, tableBodyTextColor, setKeyOfSort, setHandleOpen, setHandleAcademyClick, modalOpen, coachList, academyList, editLanguage, ageGroup, editGender, timeSlot, coachingType, sportsCategory, sports, navigate]);

  return (
    <CustomTableComponent
      checkData={checkData}
      setCheckData={setCheckData}
      headingOfTable={mappedHeadings}
      bodyOfTable={mappedBody}
      headingRowColor={headingRowColor}
      tableBodyColor={tableBodyColor}
    />
  );
};

export default DataInsertIntoTable;
