import React from "react";
import PropTypes from "prop-types";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";

import { Typography, Box } from "@mui/material";

const CustomTimelineComponent = ({ titles }) => {
  return (
    <Timeline
      sx={{
        flexDirection: "row",
        display: "flex",
      }}
    >
      {titles.map((item, index) => (
        <TimelineItem key={index}>
          <TimelineSeparator>
            <TimelineDot
              variant="outlined"
              sx={{
                width: "50px",
                height: "50px",
                justifyContent: "center",
                backgroundColor: "grey",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#000", // Change to deep black on hover
                },
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "white" }}
              >
                {index + 1}
              </Typography>
            </TimelineDot>
            {index !== titles.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography variant="h6" sx={{ marginRight: "8px" }}>
                {item.heading}
              </Typography>
            </Box>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

CustomTimelineComponent.propTypes = {
  titles: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default CustomTimelineComponent;
