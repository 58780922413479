import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const LeftIconInput = ({
  name,
  label,
  value,
  onChange,
  multiline,
  type = "text",
  formik,
  onClickDelete,
  onFocus,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: multiline ? "flex-start" : "center",
        width: "100%",
        borderRadius: "5px",
        paddingLeft: "15px",
        border: "1px solid rgba(238, 238, 238, 0.72)",
        background: "#fff",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.1)",
        margin: "10px 0",
      }}
    >
      <EditIcon
        sx={{
          color: "#D3D1D1",
          fontSize: "24px",
          marginTop: multiline ? "15px" : 0,
        }}
      />
      <TextField
        multiline={multiline}
        rows={5}
        type={type}
        autoComplete={"off"}
        id={name}
        name={name}
        placeholder={label}
        value={value !== undefined ? String(value) : String(formik?.values[name]) || ""}
        onChange={onChange || formik?.handleChange}
        onBlur={formik?.handleBlur}
        onFocus={onFocus}
        InputProps={{
          style: {
            color: "black",
            fontSize: "16px",
            fontWeight: "400",
          },
        }}
        sx={{
          width: "100%",
          ...props,
          "& fieldset": { border: "none" },
        }}
      />
      {onClickDelete && (
        <IconButton onClick={onClickDelete}>
          <DeleteOutlinedIcon
            sx={{
              color: "#6a6a6a",
              fontSize: "24px",
              marginTop: multiline ? "15px" : 0,
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default LeftIconInput;
