export const timePeriods = {
    days: [
        "All Days",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ],
    weeks: [
        "All Weeks",
        "1st Week",
        "2nd Week",
        "3rd Week",
        "4th Week",
    ],
    months: [
        "All Months",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ]
}