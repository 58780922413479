import { Box, TextField } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

function PriceINRInput({ id, name, formik, formikField }) {
  const handleChanges = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(`${formikField}.${name}`, value);
  };
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "5px",
        border: "1px solid rgba(238, 238, 238, 0.72)",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        backgroundColor: "#fff",
        maxWidth: "150px",
        marginTop: "16px",
        marginBottom: "10px",
        padding: "0 10px",
        fontSize: "16px",
        fontWeight: 500,
        color: "#C6C6C6",
      }}
    >
      INR
      <TextField
        type="number"
        name={name}
        value={formik?.values[formikField][name]}
        onChange={handleChanges}
        onBlur={formik?.handleBlur}
        placeholder="------"
        InputProps={{
          style: {
            fontSize: "16px",
            fontWeight: 500,
            color: "#242424",
            paddingX: 0,
          },
        }}
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          paddingX: 0,
          ".css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            paddingX: "7.5px",
          },
          "& fieldset": {
            border: "none",
            padding: 0,
          },
          padding: "0!important",
        }}
      />
      <KeyboardArrowDownIcon sx={{ fontSize: "24px" }} />
    </Box>
  );
}

export default PriceINRInput;
