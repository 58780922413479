import { Box, Typography } from "@mui/material";
import React from "react";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import LeftIconInput from "./LeftIconInput";
import InputError from "./inputError";

function DescribeYourSelf({ formik, name = "bio" }) {
  return (
    <Box>
      <Typography
        sx={{
          fontWeight: 600,
          marginBottom: "20px",
        }}
      >
        Describe yourself <InfoRoundedIcon sx={{ fontSize: "18px" }} />
      </Typography>
      <LeftIconInput multiline name={name} label={"Type"} formik={formik} />
      {formik.touched[name] && formik.errors[name] && (
        <InputError error={formik.errors[name]} />
      )}
    </Box>
  );
}

export default DescribeYourSelf;
