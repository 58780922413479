import {
  Box,
  Button,
  IconButton,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import { styles } from "./styles";
import SettingTextFormField from "./settingTextFormField";
import CustomFormikHook from "../../Hooks/CustomFormikHook";
import { settingsFormSchema } from "../../Schemas";
import { useDispatch } from "react-redux";
import { editeSettingAPI } from "../../Redux/slice/editeSetting";

const SettingsPage = () => {
  const dispatch = useDispatch();
  const initialValues = {
    storeName: "",
    siteEmail: "",
    siteCopyright: "",
    allowRegistration: "Enable",
    mainWebsite: "",
    maintananceMode: false,
  };
  const formik = CustomFormikHook(initialValues, settingsFormSchema);
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(editeSettingAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>Settings</Box>
          <Box sx={styles.headingButtonBox}>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.settingFormMainBox}>
        <Box>
          <Typography sx={{ fontSize: "20px", fontWeight: "500" }}>
            Web Store Setting
          </Typography>
          <Typography sx={{ marginTop: "14px", fontWeight: "500" }}>
            Here is your basic store setting of your website
          </Typography>
        </Box>
        <SettingTextFormField
          marginTop={"50px"}
          mainLabel={"Site Name"}
          description={"Specify the name of your website."}
          handleChange={formik.handleChange}
          name="storeName"
          placeholder={"Enter Your Site NameS"}
          value={formik.values.storeName}
          errors={formik.errors.storeName}
          touched={formik.touched.storeName}
        />
        <SettingTextFormField
          mainLabel={"Site Email"}
          description={"Specify the email address of your website."}
          placeholder={"Enter Your Site Email"}
          value={formik.values.siteEmail}
          handleChange={formik.handleChange}
          name="siteEmail"
          errors={formik.errors.siteEmail}
          touched={formik.touched.siteEmail}
        />
        <SettingTextFormField
          mainLabel={"Site Copyright"}
          description={"Copyright information of your website."}
          value={formik.values.siteCopyright}
          handleChange={formik.handleChange}
          name="siteCopyright"
          placeholder={"Enter Your Site Copyright Info"}
          errors={formik.errors.siteCopyright}
          touched={formik.touched.siteCopyright}
        />
        <SettingTextFormField
          mainLabel={"Allow Registration"}
          description={"Enable or disable registration from site."}
          descriptionColor="#8094AE"
          TextFieldRequired={false}
          errors={formik.errors.allowRegistration}
          touched={formik.touched.allowRegistration}
          component={
            <RadioGroup
              sx={{ width: "500px" }}
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="allowRegistration"
              value={formik.values.allowRegistration}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="Enable"
                control={<Radio sx={{ color: "primary.main" }} />}
                label="Enable"
              />
              <FormControlLabel
                value="disable"
                control={<Radio sx={{ color: "primary.main" }} />}
                label="Disable"
              />
              <FormControlLabel
                value="onRequest"
                control={<Radio sx={{ color: "primary.main" }} />}
                label="On Request"
              />
            </RadioGroup>
          }
        />
        <SettingTextFormField
          mainLabel={"Main Website"}
          description={"Specify the URL if your main website is external."}
          value={formik.values.mainWebsite}
          handleChange={formik.handleChange}
          placeholder={"Enter Your Main Website URL"}
          name="mainWebsite"
          errors={formik.errors.mainWebsite}
          touched={formik.touched.mainWebsite}
        />
        <SettingTextFormField
          mainLabel={"Maintanance Mode"}
          description={"Enable to make website make offline."}
          TextFieldRequired={false}
          component={
            <FormControlLabel
              sx={{ color: "primary.main", width: "500px", paddingX: "10px" }}
              control={
                <Switch
                  sx={styles.settingSwitchButton}
                  checked={formik.values.maintenanceMode}
                  name="maintenanceMode"
                  onChange={formik.handleChange}
                  errors={formik.errors.maintenanceMode}
                  touched={formik.touched.maintenanceMode}
                />
              }
              label={formik.values.maintenanceMode}
            />
          }
        />
        <Box
          sx={{
            justifyContent: "center",
            display: "flex",
            marginTop: "50px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              color: "#FFF",
            }}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SettingsPage;
