import { createTheme } from "@mui/material";

export const Colors = {
  primaryMain: "#3CB0AD",
  primaryLight: "#FFFFFF",
  primaryDark: "#242424",
  primarytext: "#323842",
  success: "#07A803",
  error: "#EC5347",
};

export const theme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        ul: {
          listStyle: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      main: Colors.primaryMain,
      light: Colors.primaryLight,
      dark: Colors.primaryDark,
    },
    error: {
      main: Colors.error,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Sen, sans-serif,Avenir",
  },
});

export const toastTheme = {
  styles: {
    borderRadius: "10px",
    background: "#333",
    color: "#fff",
    boxShadow: "0px 8px 24px -6px #b3b3b3, 0px 0px 1px #b3b3b3",
    fontSize: "18px",
    lineHeight: "20px",
    padding: "15px",
  },
  iconTheme: {
    width: "20px",
    height: "20px",
  },

  success: {
    duration: 3000,
    style: {
      background: Colors.success,
    },
    iconTheme: {
      primary: "#fff",
      secondary: Colors.success,
    },
  },
  error: {
    duration: 3000,
    style: {
      background: Colors.error,
    },
    iconTheme: {
      primary: "#fff",
      secondary: Colors.error,
    },
  },
};
