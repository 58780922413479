import React from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";

const CustomButtonComponent = ({
  variant,
  color,
  size,
  onClick,
  disabled,
  className,
  style,
  ...props
}) => {
  return (
    <Button
      variant={variant}
      color={color}
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={className}
      style={style}
      {...props}
    >
      {props.children}
    </Button>
  );
};

CustomButtonComponent.propTypes = {
  variant: PropTypes.oneOf(["text", "outlined", "contained"]),
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "success",
    "error",
    "info",
    "warning",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

CustomButtonComponent.defaultProps = {
  variant: "contained",
  color: "primary",
  size: "medium",
  onClick: () => {},
  disabled: false,
};

export default CustomButtonComponent;

// Console.log("PR Generated")
