import React from "react";
import { Box, Typography } from "@mui/material";
import CustomTextFieldComponent from "../../../../Components/CustomTextField";

const CategoryFormField = ({
  label,
  TextFieldRequired = true,
  placeholder,
  component,
  value,
  handleChange,
  name,
  errors,
  touched,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography sx={{ fontSize: "14px", lineHeight: "24px" }}>
          {label}
        </Typography>
      </Box>
      <>
        {TextFieldRequired ? (
          <Box>
          <CustomTextFieldComponent
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-root": {
              borderRadius: "5px",
              borderWidth: "1px",
              height: "45px",
            },
          }}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
            />
            {errors && touched ? (
              <Typography
                sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
              >
                {errors}
              </Typography>
            ) : null}
          </Box>
      ) : (
            <Box>
            {component}
            {errors && touched ? (
              <Typography
                sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
              >
                {touched}
              </Typography>
            ) : null}
          </Box>
      )}
      </>
    </Box>
  );
};

export default CategoryFormField;
