import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { USER_URLS } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const usersListAPI = createAsyncThunk("usersListAPI", async () => {
  try {
    const response = await instance.get(USER_URLS.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return { error: error.message };
  }
});

export const userStatusUpdateAPI = createAsyncThunk("usersStatusUpdateAPI", async (data) => {
  try {
    const response = await instance.put(USER_URLS.UPDATE_STATUS(data.id), data.body);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return { error: error.message };
  }
});


export const userDeleteAPI = createAsyncThunk(
  "userStatusUpdateAPI",
  async (data) => {
    try {
      const response = await instance.delete(USER_URLS.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return { error: error.message };
    }
  }
)

const usersList = createSlice({
  name: "usersList",
  initialState,
  reducers: {
    deleteUser: (state, action) => {
      console.log(action.payload.id);
      state.data = state.data.filter(
        (coach) => coach._id !== action.payload.id
      );
    },
    updateUserStatus: (state, action) => {
      state.data = state.data.map((coach) =>
        coach._id === action.payload.id
          ? { ...coach, active: action.payload.status }
          : coach
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(usersListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(usersListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(usersListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { deleteUser, updateUserStatus } = usersList.actions;
export default usersList.reducer;
