import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../.././API/BaseUrl";
import { TRACKING_URL } from "../../constants";
// import { API_URL } from "../API/config";

const initialState = {
  currentUser: null,
  loading: false,
  error: null,
};

export const createTrackingAPI = createAsyncThunk(
  "createTracking",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `${TRACKING_URL}`,
        formdata
      );
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

const createTracking = createSlice({
  name: "createTracking",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(createTrackingAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTrackingAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(createTrackingAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.error("API Error:", action.payload);
      });
  },
});

export default createTracking.reducer;
