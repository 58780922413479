import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { AGE_GROUP_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
};

export const ageGroupListAPI = createAsyncThunk("ageGroupListAPI", async () => {
  try {
    const response = await instance.get(AGE_GROUP_URL.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const ageGroupGetByIdAPI = createAsyncThunk(
  "ageGroupGetById",
  async (data) => {
    try {
      const response = await instance.get(AGE_GROUP_URL.GET(data.id));
      console.log(response);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const ageGroupCreateAPI = createAsyncThunk(
  "ageGroupCreate",
  async (formdata) => {
    try {
      const response = await instance.post(AGE_GROUP_URL.CREATE, formdata);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const ageGroupEditAPI = createAsyncThunk(
  "ageGroupEdit",
  async (data) => {
    try {
      const response = await instance.put(
        AGE_GROUP_URL.UPDATE(data.id),
        data.formdata
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const ageGroupStatusUpdateAPI = createAsyncThunk(
  "ageGroupStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(
        AGE_GROUP_URL.UPDATE_STATUS(data.id),
        data.body
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const AgeGroupDeleteAPI = createAsyncThunk(
  "ageGroupDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(AGE_GROUP_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const ageGroupList = createSlice({
  name: "ageGroupList",
  initialState,
  reducers: {
    ageGroupStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
    deleteAgeGroup: (state, action) => {
      let data = state.data?.filter((item) => item._id !== action.payload.id);
      state.data = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ageGroupListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(ageGroupListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list;
    });
    builder.addCase(ageGroupListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});

export const { ageGroupStatusUpdate, deleteAgeGroup } = ageGroupList.actions;
export default ageGroupList.reducer;
