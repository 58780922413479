import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useCallback } from "react";
import LeftIconInput from "./LeftIconInput";
import AddButtonWithLabel from "./addButtonWithLabel";
import useDebounce from "../../Hooks/debouncingHook";
import SearchSuggestions from "./searchSuggestions";

function InputFieldsWithSearch({
  formik,
  formikFieldName,
  label,
  title,
  search,
  buttonLabel,
}) {
  const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);
  const [searchResult, setSearchResult] = useState([]);
  const debounceValue = useDebounce(
    formik.values[formikFieldName][focusedFieldIndex]?.label,
    500
  );

  const handleDelete = (index) => () => {
    const values = [...formik.values[formikFieldName]];
    if (values.length === 1) return;
    values.splice(index, 1);
    formik.setFieldValue(formikFieldName, values);
  };

  const handleAddSport = () => {
    formik.setFieldValue(formikFieldName, [
      ...formik.values[formikFieldName],
      { id: "", label: "" },
    ]);
  };

  const handleFocus = (index) => (e) => {
    setFocusedFieldIndex(index);
  };

  const handleValueChange = (index) => (e) => {
    const values = [...formik.values[formikFieldName]];
    values[index] = { id: "", label: e.target.value };
    formik.setFieldValue(formikFieldName, [...values]);
  };

  const handleBlur = (e) => {
  }

  const handelSearch = useCallback(
    async (query) => {
      const res = await search(query);
      setSearchResult(res.data.list);
    },
    [search]
  );

  useEffect(() => {
    if (
      debounceValue &&
      formik.values[formikFieldName][focusedFieldIndex]?.id === ""
    ) {
      handelSearch(debounceValue);
    } else if (debounceValue === "") {
      setSearchResult([]);
    }
  }, [
    debounceValue,
    focusedFieldIndex,
    formik.values,
    formikFieldName,
    handelSearch,
  ]);

  return (
    <Box sx={{ margin: "25px 0" }} id={formikFieldName}>
      <Typography fontSize={"18px"} fontWeight={600}>
        {title}
      </Typography>
      {formik.values[formikFieldName]?.map((sport, index) => (
        <React.Fragment key={index}>
          <LeftIconInput
            key={index}
            name={`${formikFieldName}[${index}].label`}
            label={label}
            value={formik.values[formikFieldName][index].label}
            onChange={handleValueChange(index)}
            onBlur={handleBlur}
            // formik={formik}
            onClickDelete={index === 0 ? null : handleDelete(index)}
            onFocus={handleFocus(index)}
          />
          {focusedFieldIndex === index && searchResult.length > 0 && (
            <Box position={"relative"}>
              <SearchSuggestions
                searchResult={searchResult}
                clearSearch={() => setSearchResult([])}
                index={index}
                formik={formik}
                formikFieldName={formikFieldName}
              />
            </Box>
          )}
        </React.Fragment>
      ))}
      <AddButtonWithLabel
        onClick={handleAddSport}
        label={buttonLabel}
      />
    </Box>
  );
}

export default InputFieldsWithSearch;
