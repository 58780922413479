import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import instance from "../.././API/BaseUrl";
import { OFFER_URL } from "../../constants";
// import { API_URL } from "../API/config";

const initialState = {
  currentUser: null,
  loading: false,
  error: null,
};

export const editeOfferAPI = createAsyncThunk(
  "editeOffer",
  async (formdata, { rejectWithValue }) => {
    try {
      const response = await instance.post(
        `${OFFER_URL}`,
        formdata
      );
      // console.log(response);;
      return response;
    } catch (err) {
      console.log(err.message);
      return rejectWithValue(err);
    }
  }
);

const editeOffer = createSlice({
  name: "editeOffer",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(editeOfferAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(editeOfferAPI.fulfilled, (state, action) => {
        state.loading = false;
        state.currentUser = action.payload;
      })
      .addCase(editeOfferAPI.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.error("API Error:", action.payload);
      });
  },
});

export default editeOffer.reducer;
