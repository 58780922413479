import React, { useEffect, useState } from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { styles, tableHead, tableBody } from "./styles";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import CategoryFormField from "./categoryFormField";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { storeDetailFormSchema } from "../../../../Schemas";

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";

import { useDispatch } from "react-redux";
import { fetchCategory } from "../../../../Redux/slice/categorySlice";

import { DatePicker } from "antd";
import monent from "moment";
const { RangePicker } = DatePicker;

dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const CategoryList = () => {
  const dispatch = useDispatch();

  const InitialValues = {
    SearchbyStore: "",
    Coupon: "",
    OfferStatus: "",
    OfferType: "",
  };

  const formik = CustomFormikHook(InitialValues, storeDetailFormSchema);

  const itemsPerPage = 5;
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const head = tableHead;
  const body = tableBody;
  const sortedBody = body.sort((a, b) => {
    const nameA = a[keyOfSort][0].content.toUpperCase();
    const nameB = b[keyOfSort][0].content.toUpperCase();
    return nameA.localeCompare(nameB);
  });
  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [dates, setDates] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await dispatch(fetchCategory()).unwrap();
        // // console.log(response);;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>Category List</Box>
          <Box sx={styles.buttonContainer}>
            <Button sx={styles.button}>
              <Typography sx={styles.filterTypoText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </Button>
            <Button sx={styles.exportButtonBox}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography sx={styles.exportTypoText}>Export</Typography>
            </Button>
            <IconButton sx={styles.addIconButtonBox}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.createFormBox}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "25%",
              }}
            >
              <CategoryFormField
                placeholder={"Search by Store"}
                name={"SearchbyStore"}
                handleChange={formik.handleChange}
                value={formik.values.SearchbyStore}
                errors={formik.errors.SearchbyStore}
                touched={formik.touched.SearchbyStore}
              />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <CategoryFormField
                placeholder={"Coupon"}
                name={"Coupon"}
                handleChange={formik.handleChange}
                value={formik.values.Coupon}
                errors={formik.errors.Coupon}
                touched={formik.touched.Coupon}
              />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <CategoryFormField
                placeholder={"Offer Status"}
                name={"OfferStatus"}
                handleChange={formik.handleChange}
                value={formik.values.OfferStatus}
                errors={formik.errors.OfferStatus}
                touched={formik.touched.OfferStatus}
              />
            </Box>
            <Box
              sx={{
                width: "25%",
                marginLeft: "25px",
              }}
            >
              <CategoryFormField
                placeholder={"Offer Type"}
                name={"OfferType"}
                handleChange={formik.handleChange}
                value={formik.values.OfferType}
                errors={formik.errors.OfferType}
                touched={formik.touched.OfferType}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginBottom: "30px" }}>
            <Box
              sx={{
                marginRight: "70px",
                width: "33%",
                marginTop: "30px",
                height: "10x",
              }}
            >
              <div style={{ marginBottom: 10, fontSize: 18 }}>Created At</div>
              <div style={{ width: "150%", height: "200%" }}>
                <RangePicker
                  style={{ width: 430, height: 45 }}
                  onChange={(values) => {
                    setDates(
                      values.map((item) => {
                        return monent(item).format("DD-MM-YYYY");
                      })
                    );
                  }}
                />
              </div>
            </Box>
            <Box
              sx={{
                marginRight: "70px",
                width: "33%",
                marginTop: "30px",
                height: "10x",
              }}
            >
              <div style={{ marginBottom: 10, fontSize: 18 }}>Created At</div>
              <div style={{ width: "150%", height: "200%" }}>
                <RangePicker
                  style={{ width: 430, height: 45 }}
                  onChange={(values) => {
                    setDates(
                      values.map((item) => {
                        return monent(item).format("DD-MM-YYYY");
                      })
                    );
                  }}
                />
              </div>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: "25px" }}>
              <CustomButtonComponent sx={{ color: "white" }}>
                Clear
              </CustomButtonComponent>
            </Box>
            <Box>
              <CustomButtonComponent
                sx={{ color: "white", backgroundColor: "#FF9800" }}
              >
                Search
              </CustomButtonComponent>
            </Box>
          </Box>
        </Box>

        <Box sx={styles.pagesDetailMainBox}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {body.length}
            </span>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>
              in total
            </span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={{ color: "#6E7787", marginLeft: "6px" }}>Show</span>
            <span style={styles.pagesDetailNumber}>{startIndex + 1}</span>
            <span style={styles.pagesDetailText}>to</span>
            <span style={styles.pagesDetailNumber}>
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.pagesDetailText}>of</span>
            <span style={styles.pagesDetailNumber}>{body.length}</span>
            <span style={styles.pagesDetailText}>results</span>
          </Typography>
        </Box>
        <Box>
          <DataInsertIntoTable
            headingOfTable={head}
            bodyOfTable={currentItems}
            headingRowColor={"teal"}
            setKeyOfSort={setKeyOfSort}
            headingTextColor={"#FFFFFF"}
            tableBodyColor={"white"}
            tableBodyTextColor={"#171A1F"}
          />
        </Box>
        <Box
          sx={{
            pl: "6px",
            marginTop: "20px",
          }}
        >
          <CustomPagination
            type={"text"}
            totalPages={totalPages}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </Box>
      </Box>
    </>
  );
};

export default CategoryList;
