import axios from "axios";
import { store } from "../Redux";
import { BASE_URL } from "../constants";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

instance.interceptors.request.use(function (config) {
  const accessToken = store.getState()?.login?.currentUser?.token;

  config.headers["Authorization"] = `Bearer ${accessToken}`;

  return config;
});

export default instance;
