import React, { useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "./styles";
import CreateSportsFormField from "./createSportsFormField";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { createSportsSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  sportsCategoriesAPI,
  sportsCreateAPI,
} from "../../../../Redux/slice/sportsSlice";
import CustomUploadButtonComponent from "../../../../Components/customUploadButton";

const SportCreatePsports = () => {
  const dispatch = useDispatch();
  const sportsCategories = useSelector((state) => state?.sports?.categories);

  const initialValues = {
    image: "",
    metaTitle: "",
    metaDescription: "",
    sportCategory: "",
  };

  const formik = CustomFormikHook(initialValues, createSportsSchema);

  const handleChange = (event) => {
    formik.setFieldValue("sportCategory", event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formdata = new FormData();

    let body = formik.values;
    let image = formik.values.image;
    delete body.image;

    const blobJsonData = new Blob([JSON.stringify(body)], {
      type: "application/json",
    });

    formdata.append("body", blobJsonData);
    formdata.append("file", image);
    dispatch(sportsCreateAPI(formdata));
  };

  useEffect(() => {
    dispatch(sportsCategoriesAPI());
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Create Sport</Typography>
          <Box sx={styles.filterButtonContainer}>
            <CustomButtonComponent sx={styles.filterButton}>
              <Typography sx={styles.filterButtonText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon />
              <Typography sx={styles.exportButtonText}>Export</Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon sx={styles.addButtonIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateSportsFormField
          label={"Title"}
          name="metaTitle"
          placeholder={"Title"}
          handleChange={formik.handleChange}
          value={formik.values.metaTitle}
          errors={formik.errors.metaTitle}
          touched={formik.touched.metaTitle}
        />

        <CreateSportsFormField
          label={"Description"}
          name="metaDescription"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.metaDescription}
          errors={formik.errors.metaDescription}
          touched={formik.touched.metaDescription}
        />

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sports Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sportCategory}
                label="Sports Category"
                onChange={handleChange}
              >
                {sportsCategories?.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomUploadButtonComponent
            label={"Image"}
            value={formik.values.image}
            onChange={(e) => formik.setFieldValue("image", e.target.files[0])}
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomUploadButtonComponent>
        </Box>

        <Box sx={styles.editGenderFormButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Create
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default SportCreatePsports;

// import {
//   Box,
//   Button,
//   IconButton,
//   Typography,
//   TextField,
//   Switch,
// } from "@mui/material";
// import React from "react";
// import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
// import AddIcon from "@mui/icons-material/Add";
// import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
// import { styles } from "./styles";
// import { styled } from "@mui/system";
// import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
// import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
// import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
// import { genderCreateFormSchema } from "../../../../Schemas";
// import { useDispatch } from "react-redux";
// import { createGenderAPI } from "../../../../Redux/slice/creategender";

// const RequiredIndicator = styled("span")({
//   color: "red",
//   marginRight: "2px",
// });
// const SportCreatePsports = () => {
//   const dispatch = useDispatch();
//   const initialValues = {
//     name: "",
//     title: "",
//     description: "",
//     priority: "",
//     status: false,
//   };
//   const formik = CustomFormikHook(initialValues, genderCreateFormSchema);

//   const formFields = [
//     { label: "Name", placeHolder: "Enter Your Name", name: "name" },
//     { label: "Title", placeHolder: "Enter Your Title", name: "title" },
//     {
//       label: "Description",
//       placeHolder: "Your Vision, our Passion",
//       name: "description",
//     },
//     { label: "Priority", placeHolder: "eg. 123", name: "priority" },
//   ];
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     try {
//       formik.handleSubmit();
//       const finalResponse = await dispatch(createGenderAPI(formik.values));
//       console.log(finalResponse);
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <>
//       <Box sx={styles.mainBox}>
//         <Box sx={styles.headingMainBox}>
//           <Box sx={styles.headingTextBox}>Create Sport</Box>
//           <Box sx={styles.headingButtonBox}>
//             <Button sx={styles.filterButtonBox}>
//               <Typography sx={styles.filterTypoText}>Filters</Typography>
//               <img src={DownArrowIcon} alt="downArrowIcon" />
//             </Button>
//             <Button sx={styles.exportButtonBox}>
//               <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
//               <Typography sx={styles.exportTypoText}>Export</Typography>
//             </Button>
//             <IconButton sx={styles.addIconButtonBox}>
//               <AddIcon
//                 sx={{
//                   color: "primary.light",
//                   padding: "0px",
//                 }}
//               />
//               i
//             </IconButton>
//           </Box>
//         </Box>
//       </Box>
//       <Box sx={styles.editGenderFormMainBox}>
//         {formFields.map((field, index) => {
//           return (
//             <Box key={index} sx={{ marginTop: "30px" }}>
//               <Typography sx={styles.editGenderFormLabel}>
//                 <RequiredIndicator>*</RequiredIndicator>
//                 {field.label}
//               </Typography>
//               <TextField
//                 sx={styles.editGenderFormTextField}
//                 placeholder={field.placeHolder}
//                 name={field.name}
//                 value={formik.values[field.name]}
//                 onChange={formik.handleChange}
//               />
//               {formik.errors[field.name] && formik.touched[field.name] ? (
//                 <Typography
//                   sx={{ color: "red", fontSize: "12px", paddingX: "2px" }}
//                 >
//                   {formik.errors[field.name]}
//                 </Typography>
//               ) : null}
//             </Box>
//           );
//         })}
//         <Box sx={{ marginTop: "30px" }}>
//           <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
//           <Switch
//             checked={formik.values.status}
//             onChange={() =>
//               formik.setFieldValue("status", !formik.values.status)
//             }
//           />
//         </Box>
//         <Box sx={styles.editGenderFormButtonMainBox}>
//           <Button
//             variant="contained"
//             sx={styles.editGenderFormUpdateButton}
//             onClick={handleSubmit}
//           >
//             <BorderColorOutlinedIcon
//               sx={styles.editGenderFormUpdateButtonIcon}
//             />
//             Update
//           </Button>
//           <Button
//             variant="contained"
//             sx={styles.editGenderFormResetButton}
//             onClick={() => console.log("Reset clicked")}
//           >
//             <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
//             Reset
//           </Button>
//         </Box>
//       </Box>
//     </>
//   );
// };

// export default SportCreatePsports;
