import { useSelector } from "react-redux";
import Layout from "../Layout";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

const PrivateRoute = ({ children, ...rest }) => {
  const userData = useSelector((state) => state.login);
  const location = useLocation()
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData?.currentUser?.token) {
      navigate("/", { state: { from: location.pathname + location.search } });
    }
  }, [location.pathname, navigate, userData]);

  return (
    <Layout>{children}</Layout>
  )
};

export default PrivateRoute;
