import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../../API/BaseUrl";
import { GENDER_URL } from "../../constants";

const initialState = {
  isLoading: false,
  data: null,
  isError: false,
  selectedData: [],
};

export const genderListAPI = createAsyncThunk("genderListAPI", async () => {
  try {
    const response = await instance.get(GENDER_URL.LIST);
    return response.data;
  } catch (error) {
    console.log("Error", error);
    return error;
  }
});

export const GenderGetbyIdAPI = createAsyncThunk(
  "GenderGetbyId",
  async (data) => {
    try {
      const response = await instance.get(GENDER_URL.GET(data.id));
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const createGenderAPI = createAsyncThunk(
  "createGender",
  async (formdata) => {
    try {
      const response = await instance.post(GENDER_URL.CREATE, formdata);
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const GenderEditAPI = createAsyncThunk(
  "genderEdit",
  async (data) => {
    try {
      const response = await instance.put(
        GENDER_URL.UPDATE(data.id),
        data.formdata
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);


export const genderStatusUpdateAPI = createAsyncThunk(
  "genderStatusUpdate",
  async (data) => {
    try {
      const response = await instance.put(
        GENDER_URL.UPDATE_STATUS(data.id),
        data.body
      );
      return response;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

export const genderDeleteAPI = createAsyncThunk(
  "genderDeleteAPI",
  async (data) => {
    try {
      const response = await instance.delete(GENDER_URL.DELETE(data.id));
      return response.data;
    } catch (error) {
      console.log("Error", error);
      return error;
    }
  }
);

const genderList = createSlice({
  name: "genderList",
  initialState,
  reducers: {
    genderStatusUpdate: (state, action) => {
      let data = state.data?.map((item) => {
        if (item._id === action.payload.id) {
          return {
            ...item,
            active: action.payload.active,
          };
        }
        return item;
      });
      state.data = data;
    },
    deleteGender: (state, action) => {
      state.data = state.data?.filter(item => item._id !== action.payload.id)
    }
  },
  extraReducers: (builder) => {
    builder.addCase(genderListAPI.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(genderListAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action?.payload?.list;
    });
    builder.addCase(genderListAPI.rejected, (state, action) => {
      console.log("Error", action.payload);
      state.isError = true;
    });
  },
});
export default genderList.reducer;
export const { genderStatusUpdate, deleteGender } =
  genderList.actions;
