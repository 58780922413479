import React from "react";
import {
  Box,
  Avatar,
  Typography,
  IconButton,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { styles } from "./styles";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SportsIcon from "@mui/icons-material/Sports";
import LockIcon from "@mui/icons-material/Lock";
import PersonalInformation from "./PersonalInformation";
import Notifications from "./Notifications";
import AccountActivity from "./AccountActivity";
import SecuritySettings from "./SecuritySettings";

const UserProfile = () => {
  const [selectedOption, setSelectedOption] = React.useState(1);
  const userProfileOptions = [
    {
      label: "Personal Information",
      pageHeading: "User Profile",
      element: "1",
      icon: AccountBoxIcon,
      component: <PersonalInformation />,
    },
    {
      label: "Notifications",
      pageHeading: "Notifications",
      element: "2",
      icon: NotificationsIcon,
      component: <Notifications />,
    },
    {
      label: "Account Activity",
      element: "3",
      icon: SportsIcon,
      pageHeading: "Account Activity",
      component: <AccountActivity />,
    },
    {
      label: "Security Settings",
      element: "4",
      icon: LockIcon,
      pageHeading: "Security Settings",
      component: <SecuritySettings />,
    },
  ];
  const CustomListItemIcon = ({ icon: Icon, style }) => (
    <ListItemIcon
      sx={{
        minWidth: 0,
        mr: 1,
        justifyContent: "center",
      }}
    >
      <Icon
        sx={{
          color: "primary.dark",
          width: "24px",
          height: "24px",

          ...style,
        }}
      />
    </ListItemIcon>
  );
  const selectedComponent = userProfileOptions.find(
    (option) => option.element === selectedOption
  )?.component;
  const userProfilePageHeading = userProfileOptions.find(
    (option) => option.element === selectedOption
  )?.pageHeading;
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.headingMainBox}>
          <Box sx={styles.headingTextBox}>
            {userProfilePageHeading ? userProfilePageHeading : ""}
          </Box>

          {userProfilePageHeading ? (
            <Box sx={styles.headingButtonBox}>
              <Button sx={styles.exportButtonBox}>
                <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
                <Typography sx={styles.exportTypoText}>Export</Typography>
              </Button>
              <IconButton sx={styles.addIconButtonBox}>
                <AddIcon
                  sx={{
                    color: "primary.light",
                    padding: "0px",
                  }}
                />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box sx={styles.userProfileMainBox}>
        <Box>
          <Box sx={styles.sideBarFirstMainBox}>
            <Avatar sx={styles.userProfileAvatar}>AB</Avatar>
            <Box sx={{ marginX: "15px" }}>
              <Typography sx={styles.userProfileName}>
                Abu Bin Ishtiyak
              </Typography>
              <Typography sx={styles.userProfileEmail}>
                info@softonic.com
              </Typography>
            </Box>
            <IconButton>
              <MoreVertIcon sx={styles.userProfileMenuIcon} />
            </IconButton>
          </Box>
          <Box sx={styles.sideBarSeconMainBox}>
            <List>
              {userProfileOptions.map((itemObj, index) => (
                <ListItem
                  key={index}
                  disablePadding
                  sx={{
                    display: "block",
                  }}
                >
                  <Box sx={styles.listItemButtonMainBox}>
                    <ListItemButton
                      sx={styles.listItemButton}
                      onClick={() => setSelectedOption(itemObj.element)}
                    >
                      <CustomListItemIcon
                        icon={itemObj.icon}
                        style={{
                          color:
                            itemObj.element === selectedOption
                              ? "primary.main"
                              : "primary.dark",
                        }}
                      />
                      <ListItemText
                        primary={itemObj?.label}
                        sx={{
                          opacity: 1,
                          color:
                            itemObj.element === selectedOption
                              ? "primary.main"
                              : "primary.dark",
                          fontSize: "14px",
                        }}
                      />

                      <ChevronRightIcon
                        sx={{
                          color:
                            itemObj.element === selectedOption
                              ? "primary.main"
                              : "primary.dark",
                        }}
                      />
                    </ListItemButton>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box sx={styles.sideBarThirdMainBox}>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography sx={styles.sideBarThirdMainBoxThemeHeading}>
                LAST LOGIN
              </Typography>
              <Typography sx={styles.sideBarThirdMainBoxText}>
                01-02-2024 02:39pm
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography sx={styles.sideBarThirdMainBoxThemeHeading}>
                LOGIN IP
              </Typography>
              <Typography sx={styles.sideBarThirdMainBoxText}>
                192.129.243.28
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>{selectedComponent}</Box>
      </Box>
    </>
  );
};

export default UserProfile;
