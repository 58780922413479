import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import editIcon from "../../../../Assets/Icons/Drawer/editIcon.svg";
import filter from "../../../../Assets/Icons/Drawer/filter.svg";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import upDownArrow from "../../../../Assets/Icons/Drawer/upDownArrow.svg";
import CustomPagination from "../../../../Components/CustomPagination";
import DataInsertIntoTable from "../../../../Components/CustomTableComponent/dataInsertIntoTable";
import CustomButtonComponent from "../../../../Components/CustomButton";
import { styles } from "./styles";
import { useDispatch } from "react-redux";
import { fetchCategory } from "../../../../Redux/slice/storeList";

const StorePage = () => {
  const dispatch = useDispatch();

  const itemsPerPage = 5;
  const [keyOfSort, setKeyOfSort] = useState("priority");
  const [currentPage, setCurrentPage] = useState(1);
  const head = [
    {
      heading: [{ type: "text", content: "Icon" }],
      key: "icon",
      sort: false,
    },
    {
      heading: [
        { type: "text", content: "Name" },
        { type: "ButtonWithImg", content: upDownArrow },
      ],
      key: "name",
      sort: true,
    },
    {
      heading: [
        { type: "text", content: "Priority" },
        { type: "ButtonWithImg", content: upDownArrow },
      ],
      key: "priority",
      sort: true,
    },
    {
      heading: [
        { type: "text", content: "Status" },
        { type: "ButtonWithImg", content: filter },
      ],
      key: "status",
      sort: false,
    },
    {
      heading: [
        { type: "text", content: "Early Sale Cashback" },
        { type: "ButtonWithImg", content: filter },
      ],
      key: "earlySaleCashback",
      sort: false,
    },
    {
      heading: [
        { type: "text", content: "Created At" },
        { type: "ButtonWithImg", content: upDownArrow },
      ],
      key: "createdAt",
      sort: true,
    },
    {
      heading: [
        { type: "text", content: "Updated At" },
        { type: "ButtonWithImg", content: upDownArrow },
      ],
      key: "updatedAt",
      sort: false,
    },
    {
      heading: [{ type: "text", content: "Actions" }],
      key: "actions",
      sort: false,
    },
  ];
  const body = [
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "MMT Flights" }],
      priority: [{ type: "text", content: "50010" }],
      status: [{ type: "ButtonWithText", content: "Active" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Ajio" }],
      priority: [{ type: "text", content: "124341" }],
      status: [{ type: "ButtonWithText", content: "Active" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Nykaa Fashion" }],
      priority: [{ type: "text", content: "789" }],
      status: [{ type: "ButtonWithText", content: "Active" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "The Man Company" }],
      priority: [{ type: "text", content: "2578" }],
      status: [{ type: "ButtonWithText", content: "Disabled" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Bigbasket" }],
      priority: [{ type: "text", content: "221" }],
      status: [{ type: "ButtonWithText", content: "Disabled" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Bodyshop" }],
      priority: [{ type: "text", content: "2205" }],
      status: [{ type: "ButtonWithText", content: "Disabled" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Dominos" }],
      priority: [{ type: "text", content: "50010" }],
      status: [{ type: "ButtonWithText", content: "Active" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
    {
      icon: [{ type: "icon", content: editIcon }],
      name: [{ type: "text", content: "Nike" }],
      priority: [{ type: "text", content: "2345" }],
      status: [{ type: "ButtonWithText", content: "Active" }],
      earlySaleCashback: [{ type: "ButtonWithText", content: "Disabled" }],
      createdAt: [{ type: "text", content: "2024-01-31 To 12:00:00" }],
      updatedAt: [{ type: "text", content: "2024-02-01 To 12:00:00" }],
      actions: [{ type: "icon", content: editIcon }],
      new: [{ type: "icon", content: editIcon }],
    },
  ];
  console.log("Body Data");
  console.log(body);

  // Sorting logic -----------
  const sortedBody = body.sort((a, b) => {
    const nameA = a[keyOfSort][0].content.toUpperCase();
    const nameB = b[keyOfSort][0].content.toUpperCase();
    return nameA.localeCompare(nameB);
  });

  // Pagination calculation-----------
  const totalItems = sortedBody.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = sortedBody.slice(startIndex, endIndex);

  // Event handler for page change-----------
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await dispatch(fetchCategory()).unwrap();
        // console.log(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Box sx={styles.title}>Store List</Box>
          <Box sx={styles.buttonContainer}>
            <CustomButtonComponent sx={styles.button}>
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Filters
              </Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Export
              </Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box sx={styles.infoContainer}>
          <Typography>
            <span style={{ color: "#171A1F", fontWeight: "700" }}>
              {body.length}
            </span>
            <span style={styles.infoText}>in total</span>
          </Typography>

          <Typography sx={{ marginLeft: "70px" }}>
            <span style={styles.infoText}>Show</span>
            <span
              style={{ color: "#171A1F", fontWeight: "500", marginLeft: "4px" }}
            >
              {startIndex + 1}
            </span>
            <span style={styles.infoText}>to</span>
            <span
              style={{ color: "#171A1F", fontWeight: "500", marginLeft: "4px" }}
            >
              {endIndex < totalItems ? endIndex : totalItems}
            </span>
            <span style={styles.infoText}>of</span>
            <span
              style={{ color: "#171A1F", fontWeight: "500", marginLeft: "4px" }}
            >
              {body.length}
            </span>
            <span style={styles.infoText}>results</span>
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.paginationContainer}>
        <DataInsertIntoTable
          headingOfTable={head}
          bodyOfTable={currentItems}
          headingRowColor={"teal"}
          setKeyOfSort={setKeyOfSort}
          headingTextColor={"#FFFFFF"}
          tableBodyColor={"white"}
          tableBodyTextColor={"#171A1F"}
        />
        <CustomPagination
          type={"text"}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={handlePageChange}
        />
      </Box>
    </>
  );
};

export default StorePage;
