import React from "react";
import { Box, Typography, Switch, FormControlLabel } from "@mui/material";
import { styles } from "./styles";

const NotificationOptions = ({ heading, notificationOption }) => {
  return (
    <Box sx={styles.notificationOptionsMainBox}>
      <Typography sx={styles.notificationOptionsHeading}>{heading}</Typography>
      <Typography sx={styles.notificationOptionsHeadingBaseText}>
        You will get only those email notification what you want.
      </Typography>

      {notificationOption.map((item, index) => {
        return (
          <Box sx={styles.notificationOptionsSwitchMainBox}>
            <FormControlLabel
              key={index}
              control={<Switch defaultChecked={item.defaultChecked} />}
              label={
                <Typography style={styles.notificationOptionsSwitchLabelText}>
                  {item.label}
                </Typography>
              }
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default NotificationOptions;
