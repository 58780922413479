import React, { useEffect } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import CustomButtonComponent from "../../../../Components/CustomButton";
import DownArrowIcon from "../../../../Assets/Icons/Drawer/DownArrow.svg";
import AddIcon from "@mui/icons-material/Add";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styles } from "../Create/styles";
import CustomFormikHook from "../../../../Hooks/CustomFormikHook";
import { createSportsSchema } from "../../../../Schemas";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useDispatch, useSelector } from "react-redux";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSearchParams } from "react-router-dom";
import {
  SportsEditAPI,
  SportsGetByIdAPI,
  sportsCategoriesAPI,
} from "../../../../Redux/slice/sportsSlice";
import CustomUploadButtonComponent from "../../../../Components/customUploadButton";
import CreateSportsFormField from "../Create/createSportsFormField";

const SportsEditPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const sportsCategories = useSelector((state) => state?.sports?.categories);

  const initialValues = {
    image: "",
    metaTitle: "",
    metaDescription: "",
    sportCategory: "",
  };

  const formik = CustomFormikHook(initialValues, createSportsSchema);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formdata = new FormData();

    let body = formik.values;
    let image = formik.values.image;
    delete body.image;

    const blobJsonData = new Blob([JSON.stringify(body)], {
      type: "application/json",
    });

    formdata.append("body", blobJsonData);
    formdata.append("file", image);
    dispatch(
      SportsEditAPI({
        id: searchParams.get("id"),
        formdata: formdata,
      })
    );
  };

  const handleChange = (event) => {
    formik.setFieldValue("sportCategory", event.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      let data = await dispatch(
        SportsGetByIdAPI({ id: searchParams.get("id") })
      );
      data = data?.payload?.data?.data;
      formik.setValues({
        metaTitle: data?.metaTitle || "",
        metaDescription: data?.metaDescription || "",
        sportCategory: data?.sportCategory || "",
        image: "",
      });
    };

    fetchData();
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(sportsCategoriesAPI());
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.headingContainer}>
        <Box sx={styles.header}>
          <Typography sx={styles.headingTitle}>Edit Sport</Typography>
          <Box sx={styles.filterButtonContainer}>
            <CustomButtonComponent sx={styles.filterButton}>
              <Typography sx={styles.filterButtonText}>Filters</Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon />
              <Typography sx={styles.exportButtonText}>Export</Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon sx={styles.addButtonIcon} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.categoryFormBox}>
        <CreateSportsFormField
          label={"Title"}
          name="metaTitle"
          placeholder={"Title"}
          handleChange={formik.handleChange}
          value={formik.values.metaTitle}
          errors={formik.errors.metaTitle}
          touched={formik.touched.metaTitle}
        />

        <CreateSportsFormField
          label={"Description"}
          name="metaDescription"
          placeholder={"Enter Description"}
          handleChange={formik.handleChange}
          value={formik.values.metaDescription}
          errors={formik.errors.metaDescription}
          touched={formik.touched.metaDescription}
        />

        <Box>
          <Box sx={{ minWidth: 120, marginTop: "20px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Sports Category
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formik.values.sportCategory}
                label="Sports Category"
                onChange={handleChange}
              >
                {sportsCategories?.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomUploadButtonComponent
            label={"Image"}
            value={formik.values.image}
            onChange={(e) => formik.setFieldValue("image", e.target.files[0])}
            sx={{
              marginLeft: "10px",
              width: "12%",
              paddingY: "9px",
              marginTop: "38px",
              color: "primary.light",
            }}
          >
            <CloudUploadIcon sx={{ mr: "8px" }} />
            Upload
          </CustomUploadButtonComponent>
        </Box>

        <Box sx={styles.editGenderFormButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Update
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => console.log("Reset clicked")}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default SportsEditPage;
