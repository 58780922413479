import React from "react";
import { Box, Typography } from "@mui/material";
import { styles } from "./styles";
const PersonalInformationFields = ({ heading, information }) => {
  return (
    <Box>
      <Box sx={styles.fieldsMainBox}>
        <Typography sx={styles.fieldHeading}>{heading}</Typography>
      </Box>
      <Box>
        {information.map((info, index) => {
          console.log(index, information.length);
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "700px",
                paddingY: "30px",
                paddingX: "30px",
                borderBottom:
                  index + 1 === information.length ? "" : "1px solid #F2F2F2",
              }}
            >
              <Typography sx={styles.fieldsLabel}>{info.label}</Typography>
              <Typography sx={styles.fieldsValue}>
                {info.value ? info.value : "Not add yet"}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default PersonalInformationFields;
