import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Slider,
  Switch,
} from "@mui/material";
import { styles } from "./styles";
import CustomButtonComponent from "../../../../../Components/CustomButton/";
import DownArrowIcon from "../../../../../Assets/Icons/Drawer/DownArrow.svg";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import AddIcon from "@mui/icons-material/Add";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import TargetingFormField from "./targetingFormField";
import CustomFormikHook from "../../../../../Hooks/CustomFormikHook";
import { targetingFormSchema } from "../../../../../Schemas";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch } from "react-redux";
import { createTargetingAPI } from "../../../../../Redux/slice/createTargeting";

const Targeting = ({ handleNext }) => {
  const dispatch = useDispatch();
  const initialValues = {
    gender: "",
    ageRange: "",
    country: "",
    state: "",
    city: "",
    excludeState: false,
    excludeCity: false,
    status: false,
    // excludeState: "",
    // excludeCity: "",
  };
  const handleChange = () => {
    handleNext();
  };
  const formik = CustomFormikHook(initialValues, targetingFormSchema);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await formik.validateForm();
      formik.handleSubmit();
      const finalResponse = await dispatch(createTargetingAPI(formik.values));
      console.log(finalResponse);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {/* <Box sx={styles.container}> */}

      {/* <Box sx={styles.header}>
          <Typography sx={styles.title}>Edit Store</Typography>
          <Box sx={styles.buttonContainer}>
            <CustomButtonComponent sx={styles.button}>
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Filters
              </Typography>
              <img src={DownArrowIcon} alt="downArrowIcon" />
            </CustomButtonComponent>
            <CustomButtonComponent sx={styles.exportButton}>
              <VerticalAlignBottomIcon sx={{ color: "primary.light" }} />
              <Typography
                sx={{ textTransform: "capitalize", fontWeight: "500" }}
              >
                Export
              </Typography>
            </CustomButtonComponent>
            <IconButton sx={styles.addButton}>
              <AddIcon
                sx={{
                  color: "primary.light",
                  padding: "0px",
                }}
              />
            </IconButton>
          </Box>
        </Box> */}

      {/* </Box> */}
      <Box sx={styles.createFormBox}>
        {/* <TargetingFormField
          label={"Gender"}
          name={"gender"}
          TextFieldRequired={false}
          handleChange={formik.handleChange}
          value={formik.values.gender}
          errors={formik.errors.gender}
          touched={formik.touched.gender}
          component={
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={false} />}
                label={"Male"}
                name="male"
              />
              <FormControlLabel
                control={<Checkbox checked={false} />}
                label={"Female"}
                name="female"
              />
              <FormControlLabel
                control={<Checkbox checked={false} />}
                label={"Others"}
                name="others"
              />
            </FormGroup>
          }
        /> */}
        <Box sx={{ width: "25%" }}>
          <TargetingFormField
            name={"gender"}
            value={formik.values.gender}
            errors={formik.errors.gender}
            handleChange={formik.handleChange}
            touched={formik.touched.gender}
            label={"Gender"}
            placeholder={"Enter Your Gender"}
          />
        </Box>

        <Box sx={{ width: "78%" }}>
          <TargetingFormField
            name={"ageRange"}
            value={formik.values.ageRange}
            errors={formik.errors.ageRange}
            handleChange={formik.handleChange}
            touched={formik.touched.ageRange}
            label={"Age Range"}
            TextFieldRequired={false}
            component={
              <Slider
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={18}
                max={100}
                value={formik.values.ageRange}
                onChange={(event, value) =>
                  formik.setFieldValue("ageRange", value)
                }
                sx={{ margin: "5px" }}
              />
            }
          />
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ width: "25%" }}>
            <TargetingFormField
              name={"country"}
              value={formik.values.country}
              errors={formik.errors.country}
              handleChange={formik.handleChange}
              touched={formik.touched.country}
              label={"Country"}
              placeholder={"Enter Your Country"}
            />
          </Box>
          <Box sx={{ width: "25%", marginX: "25px" }}>
            <TargetingFormField
              name={"state"}
              value={formik.values.state}
              errors={formik.errors.state}
              handleChange={formik.handleChange}
              touched={formik.touched.state}
              label={"State"}
              placeholder={"Enter Your State"}
            />
          </Box>
          <Box sx={{ width: "25%" }}>
            <TargetingFormField
              name={"city"}
              value={formik.values.city}
              errors={formik.errors.city}
              handleChange={formik.handleChange}
              touched={formik.touched.city}
              label={"City"}
              placeholder={"Enter Your City"}
            />
          </Box>
        </Box>
        <Box sx={{ marginLeft: "280px", display: "flex" }}>
          <TargetingFormField
            value={formik.values.excludeState}
            errors={formik.errors.excludeState}
            handleFormFieldChange={formik.handleChange}
            touched={formik.touched.excludeState}
            TextFieldRequired={false}
            component={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.excludeState}
                    onChange={() =>
                      formik.setFieldValue(
                        "excludeState",
                        !formik.values.excludeState
                      )
                    }
                  />
                }
                label={"Exclude State"}
                name="excludeState"
              />
            }
          />
          <TargetingFormField
            value={formik.values.excludeCity}
            errors={formik.errors.excludeCity}
            handleFormFieldChange={formik.handleChange}
            touched={formik.touched.excludeCity}
            TextFieldRequired={false}
            component={
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.excludeCity}
                    onChange={() =>
                      formik.setFieldValue(
                        "excludeCity",
                        !formik.values.excludeCity
                      )
                    }
                  />
                }
                label={"Exclude City"}
                name="excludeCity"
              />
            }
          />
        </Box>
        <Box sx={{ marginTop: "30px" }}>
          <Typography sx={styles.editGenderFormStatusText}>Status</Typography>
          <Switch
            checked={formik.values.status}
            onChange={() =>
              formik.setFieldValue("status", !formik.values.status)
            }
          />
        </Box>
        <Box sx={styles.editGenderFormButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon
              sx={styles.editGenderFormUpdateButtonIcon}
            />
            Details <ArrowBackIosIcon sx={{ mr: "5px", fontSize: 13 }} /> Prev
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.editGenderFormResetButton}
            onClick={() => {
              handleChange();
            }}
          >
            <RefreshOutlinedIcon sx={styles.editGenderFormResetButtonIcon} />
            Next <ArrowForwardIosIcon sx={{ mr: "5px", fontSize: 13 }} /> Payout
          </CustomButtonComponent>
        </Box>
        <Box sx={styles.formButtonMainBox}>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
            onClick={handleSubmit}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formUpdateButton}
          >
            <BorderColorOutlinedIcon sx={styles.formUpdateButtonIcon} />
            Update Url
          </CustomButtonComponent>
          <CustomButtonComponent
            variant="contained"
            sx={styles.formResetButton}
            // onClick={() => setPage(0)}
          >
            <RefreshOutlinedIcon sx={styles.formResetButtonIcon} />
            Reset
          </CustomButtonComponent>
        </Box>
      </Box>
    </>
  );
};

export default Targeting;
