import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Box, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";

export default function CustomButton({
  children,
  width = "115px",
  height,
  clickEvent = () => {},
  disabled,
  type = "button",
  customStyle = {}, // Renamed from style to customStyle
  ...rest
}) {

  const styles = {
    backgroundColor: "#242424",
    color: "#fff",
    width: width,
    height: height,
    paddingTop: "8px",
    paddingBottom: "8px",
    cursor: "pointer",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#242424",
    },
    ...customStyle, // Merge customStyle with other styles
  };

  return (
    <Button
      type={type}
      onClick={clickEvent}
      disabled={disabled}
      disableRipple
      sx={styles}
      variant={"contained"}
      {...rest}
    >
      {children}
    </Button>
  );
}
